import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, of } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-ecommerceproductdetail',
  templateUrl: './ecommerceproductdetail.component.html',
  styleUrls: ['./ecommerceproductdetail.component.css']
})
export class EcommerceproductdetailComponent implements OnInit {
  prdcollection: any;

  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , '1PL5')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  }

}
