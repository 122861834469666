import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import { forEach,sum} from 'lodash';
import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDetailRowDirective } from './cdk-detail-row.directive';



@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.css'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class EcommerceComponent implements AfterViewInit, OnInit {

  displayedColumns = ['actions','code','brand','category','color','size','modelno','labelcode','hsncode','gst','prate','rate','qntygodown','qntyshop1','qntyshop2','totqnty','estrate','estrate2','regulardisc','primedisc','institutedisc','wholesaledisc'];
  dataSource: MatTableDataSource<any>; 
  
  displayedColumns2 = ['brand','category','code','color','size','modelno','labelcode','hsncode','gst','prate','rate','qntygodown','qntyshop1','qntyshop2','totqnty','estrate','estrate2','regulardiscP','regulardisc','primediscP','primedisc','institutediscP','institutedisc','wholesalediscP','wholesaledisc'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  totalValue: any;
  
  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder) {   }
  prcollection: any;
  qntycollection: any;

  search: any;

  custonlrate:number=0;
  b:{ id: string }[]=[];


  selectedItemsList = [];
  checkedIDs = [];

  checkboxesDataList = [
    {
      id: 'C001',
      label: 'Photography',
      isChecked: false
    },
    {
      id: 'C002',
      label: 'Writing',
      isChecked: false
    },
    {
      id: 'C003',
      label: 'Painting',
      isChecked: false
    },
  ]


  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');


  //-->expands only one at a time//
  private openedRow: CdkDetailRowDirective
  onToggleChange(cdkDetailRow: CdkDetailRowDirective) : void {
    if (this.openedRow && this.openedRow.expended) {
      this.openedRow.toggle();      
    }
    this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
    
  }
  //expands only one at a time<--//


  ngAfterViewInit() {
    this.prcollection = this.afs.collection('Products').valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    
      
       //It searches Category columns. To search in entire table skip it
       this.dataSource.filterPredicate = (data, filter) =>
       (data.category.indexOf(filter) !== -1);
       //----------------------------------------------------------------------
       console.log((this.dataSource));
     })
  }

  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toUpperCase(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;  
  };

  ngOnInit() {
    
    this.fetchSelectedItems()
    this.fetchCheckedIDs()
  }

  getTotalQntyGodown(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntygodown = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntygodown);
  });
  return tqntygodown;
  }
  }

  getTotalQntyShop1(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntyshop1 = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntyshop1);
  });
  return tqntyshop1;
  }
  }

  getTotalQntyShop2(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntyshop2 = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntyshop2);
  });
  return tqntyshop2;
  }
  }


  actionaddbtnclicked(c,pid){
    this.afs.collection("Products", ref => ref.where('code', "==" , c)).doc(pid).update({
      onlinesale:true
    });
  }
    actionremovebtnclicked(c,pid){
      this.afs.collection("Products", ref => ref.where('code', "==" , c)).doc(pid).update({
        onlinesale:false
      });
    }




    validateId(c) {
      return this.b.find(x => x.id === c);
      }
    
      customonlrateeditbtnclkview(c,pkid,q){
        this.afs.collection('Products', ref => ref.where('code', '==', c)).doc(pkid).get().toPromise().then(  doc => {
          this.custonlrate=doc.data().onlinerate;
      })
      this.b=[];
      this.b.push({'id':c});
    }
    
    
    customonlrateeditbtnclkclose(c,pkid,q){
      this.b=[];
      console.log(q);
      this.customonlrate(c,pkid,q);
    }


    customonlrate(bcode,productkeyid,cstmonlrate){
      this.afs.collection('Products', ref => ref.where('code', '==', bcode)).doc(productkeyid).update({
       onlinerate:cstmonlrate
      })
    }


    changeSelection() {
      this.fetchSelectedItems()
    }
  
    fetchSelectedItems() {
      this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
        return value.isChecked
      });
    }
  
    fetchCheckedIDs() {
      this.checkedIDs = []
      this.checkboxesDataList.forEach((value, index) => {
        if (value.isChecked) {
          this.checkedIDs.push(value.id);
        }
      });
    }

}
