import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import {DatePipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { FlexLayoutModule } from '@angular/flex-layout';

import { QRCodeModule } from 'angularx-qrcode';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule, MatTooltipModule, MatChipsModule, MatSnackBarModule, MatCardModule, MatIconModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatRadioModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatCheckboxModule, MatListModule, MatProgressBarModule, MatGridListModule, MatStepperModule, MatSidenavModule, MatToolbarModule, MatBottomSheetModule, MatButtonToggleModule} from '@angular/material';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPrintModule } from 'ngx-print';
import { ConfirmationDialog } from './productbuild/confirmation-dialog.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { ProductentryComponent } from './productentry/productentry.component';
import { ParticularsComponent } from './particulars/particulars.component';
import { LedgerdetailsComponent } from './ledgerdetails/ledgerdetails.component';
import { ProductcardsComponent } from './productcards/productcards.component';
import { SidebarlComponent } from './sidebarl/sidebarl.component';
import { PaperlayoutComponent } from './paperlayout/paperlayout.component';
import { ProductcrudComponent } from './productcrud/productcrud.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { AllinoneComponent } from './auth/allinone/allinone.component';
import { HomeComponent } from './home/home.component';
import { ProductbaseComponent } from './productbase/productbase.component';
import { ProductcommondetailsComponent } from './productcommondetails/productcommondetails.component';
import { ProductbuildComponent } from './productbuild/productbuild.component';
import { Drawer2Service } from 'src/assets/services/drawer2.service';
import { BillentryComponent } from './billentry/billentry.component';
import { ProductmigrationComponent } from './productmigration/productmigration.component';
import { BarcodelayoutComponent } from './barcodelayout/barcodelayout.component';
import { ProductfiltersComponent } from './productfilters/productfilters.component';
import { filteruniquebrand } from 'src/assets/shared/sharedpipes/filteruniquebrand.pipe';
import { filteruniquecategory } from 'src/assets/shared/sharedpipes/filteruniquecategory.pipe';
import { filteruniquesize } from 'src/assets/shared/sharedpipes/filteruniquesize.pipe';
import { filteruniquecolor } from 'src/assets/shared/sharedpipes/filteruniquecolor.pipe';
import { filteruniquecode } from 'src/assets/shared/sharedpipes/filteruniquecode.pipe';
import { filterproduct } from 'src/assets/shared/sharedpipes/filterproduct.pipe';
import { GroupByPipe } from 'src/assets/shared/sharedpipes/group-by.pipe';
import { NumberToWordsPipe } from 'src/assets/shared/sharedpipes/number-to-words.pipe';

import { NgPipesModule } from 'ngx-pipes';
import { AvatarModule } from 'ngx-avatar';

import { BilldetailsComponent } from './billdetails/billdetails.component';
import { EmployeesComponent } from './employees/employees.component';
import { CustomersComponent } from './customers/customers.component';
import { SettingsComponent } from './settings/settings.component';
import { BillreturnsComponent } from './billreturns/billreturns.component';
import { Drawer3Service } from 'src/assets/services/drawer3.service';
import { BrandscategoriesComponent } from './brandscategories/brandscategories.component';
import { BilldeleteComponent, BottomSheetBillDelete } from './billdelete/billdelete.component';
import { ProductmigrationoutComponent } from './productmigrationout/productmigrationout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductmigrationinComponent } from './productmigrationin/productmigrationin.component';
import { ProductmigrationstockComponent } from './productmigrationstock/productmigrationstock.component';
import { ProductmigrationmanageComponent } from './productmigrationmanage/productmigrationmanage.component';
import { BarcodelayoutnewstockComponent } from './barcodelayoutnewstock/barcodelayoutnewstock.component';
import { Drawer10Service } from 'src/assets/services/drawer10.service';
import { Drawer11Service } from 'src/assets/services/drawer11.service';
import { Drawer12Service } from 'src/assets/services/drawer12.service';
import { Drawer13Service } from 'src/assets/services/drawer13.service';
import { ReportsComponent } from './reports/reports.component';
import { DisplaybannersComponent } from './displaybanners/displaybanners.component';
import { CustomerprofileComponent, DialogDataExampleDialog, DialogDataExampleDialogERONE, DialogDataExampleDialogERTWO, DialogDataExampleDialogERVIEW, DialogDataExampleDialogERVIEWTP } from './customerprofile/customerprofile.component';
import { CustomerprofileService } from 'src/assets/services/customerprofile.service';
import { AlterstockComponent } from './alterstock/alterstock.component';
import { BarcodelayoutnewprdComponent } from './barcodelayoutnewprd/barcodelayoutnewprd.component';
import { LedgerbooksComponent } from './ledgerbooks/ledgerbooks.component';
import { LedgerfilterComponent } from './ledgerfilter/ledgerfilter.component';
import { LedgerdetailsService } from 'src/assets/services/ledgerdetails.service';
import { BookstagsComponent } from './bookstags/bookstags.component';
import { ReportdetailsComponent } from './reportdetails/reportdetails.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { EstimationComponent } from './estimation/estimation.component';
import { TopsellingComponent } from './topselling/topselling.component';
import { ProfitbillsComponent } from './profitbills/profitbills.component';
import { EcommerceComponent } from './ecommerce/ecommerce.component';
import { EcategorygroupComponent } from './ecategorygroup/ecategorygroup.component';
import { CdkDetailRowDirective } from './ecommerce/cdk-detail-row.directive';
import { EcommerceproductdetailComponent } from './ecommerceproductdetail/ecommerceproductdetail.component';
import { EcommerceproductlistComponent } from './ecommerceproductlist/ecommerceproductlist.component';
import { BilleditComponent } from './billedit/billedit.component';
import { Drawer4Service } from 'src/assets/services/drawer4.service';

var config = {
  apiKey: "AIzaSyCry8-SZXhTdMXmu2BLyPoMYbicpRXQanE",
  authDomain: "pointofsaledl.firebaseapp.com",
  databaseURL: "https://pointofsaledl.firebaseio.com",
  projectId: "pointofsaledl",
  storageBucket: "pointofsaledl.appspot.com",
  messagingSenderId: "545771392054",
  appId: "1:545771392054:web:34e2bcdb2e1011fac75c97",
  measurementId: "G-XX63ZNX03T"
};
// var config = {
//   apiKey: "AIzaSyDlv4HTpbbziDwZyEavFUP_L2GoibT8qks",
//   authDomain: "pointofsale-9ce65.firebaseapp.com",
//   databaseURL: "https://pointofsale-9ce65.firebaseio.com",
//   projectId: "pointofsale-9ce65",
//   storageBucket: "pointofsale-9ce65.appspot.com",
//   messagingSenderId: "46346040404",
//   appId: "1:46346040404:web:8d2285fa04979c6d9269c1",
//   measurementId: "G-Q0LTF1T6J0"
// };
// var config = {
//   apiKey: "AIzaSyAhWfR5pE39LnmnNXBRH2poLkLP0Mh1tvg",
//   authDomain: "tasppointofsaledl.firebaseapp.com",
//   projectId: "tasppointofsaledl",
//   storageBucket: "tasppointofsaledl.appspot.com",
//   messagingSenderId: "125126371940",
//   appId: "1:125126371940:web:63b43f6057b37ef7874d71",
//   measurementId: "G-VQJENY8X7W"
// };
enableProdMode();

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    QRCodeModule,
    AngularFontAwesomeModule,
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatChipsModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule, 
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCarouselModule.forRoot(),
    MatTableExporterModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgxPrintModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireDatabaseModule, //database
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    NgPipesModule,
    AvatarModule,
    ],
  providers: [MatDatepickerModule,DatePipe,Drawer2Service,Drawer3Service,Drawer4Service,Drawer10Service,Drawer11Service,Drawer12Service,Drawer13Service,CustomerprofileService,LedgerdetailsService],
  entryComponents: [ConfirmationDialog, BilldeleteComponent, BottomSheetBillDelete, CustomerprofileComponent, DialogDataExampleDialog, DialogDataExampleDialogERONE, DialogDataExampleDialogERTWO, DialogDataExampleDialogERVIEW, DialogDataExampleDialogERVIEWTP],
  declarations: [ NumberToWordsPipe, GroupByPipe, filteruniquebrand, filteruniquecategory, filteruniquesize, filteruniquecolor, filteruniquecode, filterproduct, AppComponent, ProductdetailsComponent, ProductentryComponent, ParticularsComponent, LedgerdetailsComponent, ProductcardsComponent, SidebarlComponent, PaperlayoutComponent, ProductcrudComponent, LoginComponent, RegistrationComponent, ResetpasswordComponent, AllinoneComponent, HomeComponent, ProductbaseComponent, ProductcommondetailsComponent, ProductbuildComponent, ConfirmationDialog, BillentryComponent, ProductmigrationComponent, BarcodelayoutComponent, ProductfiltersComponent, BilldetailsComponent, EmployeesComponent, CustomersComponent, SettingsComponent, BillreturnsComponent, BrandscategoriesComponent, BilldeleteComponent, BottomSheetBillDelete, ProductmigrationoutComponent, DashboardComponent, ProductmigrationinComponent, ProductmigrationstockComponent, ProductmigrationmanageComponent, BarcodelayoutnewstockComponent, ReportsComponent, DisplaybannersComponent, CustomerprofileComponent, DialogDataExampleDialog, DialogDataExampleDialogERONE, DialogDataExampleDialogERTWO, DialogDataExampleDialogERVIEW, DialogDataExampleDialogERVIEWTP, AlterstockComponent, BarcodelayoutnewprdComponent, BookstagsComponent, LedgerbooksComponent, LedgerfilterComponent, LedgerfilterComponent, BookstagsComponent, ReportdetailsComponent, SuppliersComponent, EstimationComponent, TopsellingComponent, ProfitbillsComponent, EcommerceComponent,EcategorygroupComponent,CdkDetailRowDirective, EcommerceproductdetailComponent, EcommerceproductlistComponent, BilleditComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

