import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import 'rxjs';
import { groupBy, mergeMap, toArray, debounceTime, tap } from 'rxjs/operators';

import { from, zip, of } from 'rxjs';
export interface Item { name: string; rate: number;}
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { equal } from 'assert';
import { database } from 'firebase';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import * as moment from 'moment';

    @Component({
      selector: 'app-billentry',
      templateUrl: './billentry.component.html',
      styleUrls: ['./billentry.component.css']
    })

export class BillentryComponent implements OnInit {

  bufferValue=0;
  value=0; //progressbar
  favoriteCustomer: string;
  customers: string[] = ['regular', 'prime', 'institute', 'wholesale'];
  billoc: string;
  billocations: string[] = ['shop1', 'shop2', 'Godown'];
  modeofpay: string;
  pmodes: string[] = ['CASH', 'CARD', 'UPI PAYMENT', 'COMBINE'];
  cbilly: string;
  iscbill: string[] = ['yes', 'no'];

  summed1: any[] = [];
  summed2: any[] = [];
  summed3: any[] = [];

  taxy:string;
  taxes = [{label:'gst',checked:true}, {label:'igst',checked:false}, {label:'taxfree',checked:false}];
  
    TaxnameGST: string='gst';
    TaxnameIGST: string='0';
    TaxnameTF: string='0';

  tottaxableamt: number;
  totamtwithtax: number;

  sumaftrdisc:number;
  sumprateaftrqnty:number;

  totalamount: number;
  totalpamount: number;
  totalrate: number;
  totalprate: number;
  summy: { gst: number, rate: number, prate: number, taxableamt: number, amtaftdisc:number, prateaftqnty:number }[]= [];
  hello: string;
  custid: any;
  custname: any;
  custimage: any;
  custtype: any;
  custdiscounttype: any;
  custaddress11: any;
  custaddress12: any;
  custaddress21: any;
  custaddress22: any;
  custcontact: any;
  custgst: any;
  custhonor: any;
  custaddressS1: any;
  custaddressS2: any;
  sameaddr: boolean;
  prdkeyid: any;
  bcbtndisabled: boolean;
  clrbtndisabled: boolean;
  addbtndisabled: boolean;

  billqnty: any;
  prdqnty: any;
  rembtndisabled: boolean;

  arry:{
    code:string;
    qnty:number;
  }[]=[];

  prdkeyidarray:string[]=[];
  billqntyarray:number[]=[];

  totbills:number;
  crcollection: any;

  allroutineidarry: { id: number, location: string, status: string }[]=[];
  completedroutineidarry: { id: number, location: string, status: string }[]=[];
  uncompletedroutineidarry: { id: number, location: string, status: string }[]=[];
      eventdate: string;
      crd: number=0;
      dbt: number=0;


      custqnty:number=0;
      b:{ id: string }[]=[];
      mandisc: number=0;
      ocharges: number=0;
      mdinp: number=0;
     
      mdinpchng(){        
this.mandisc=Number((Number((this.sumaftrdisc).toFixed(0))*(this.mdinp/100)).toFixed(0));
      }

  updateCheckedOptions(index, tax) {
   
    tax.checked = !tax.checked;
    //----newly added--------
    tax.checked=true;
    //----newly added--------
    console.log(index,tax.checked);

    this.updateradiobuttons();

    if(tax.checked==true)
    {
    if(index==0){this.TaxnameGST='gst';this.TaxnameIGST='0';this.TaxnameTF='0';this.taxes = [{label:'gst',checked:true}, {label:'igst',checked:false}, {label:'taxfree',checked:false}];}
    if(index==1){this.TaxnameIGST='igst';this.TaxnameGST='0';this.TaxnameTF='0';this.taxes = [{label:'gst',checked:false}, {label:'igst',checked:true}, {label:'taxfree',checked:false}];}
    if(index==2){this.TaxnameTF='taxfree';this.TaxnameGST='0';this.TaxnameIGST='0';this.taxes = [{label:'gst',checked:false}, {label:'igst',checked:false}, {label:'taxfree',checked:true}];}
    console.log(this.taxes);
    }
    if(tax.checked==false){
      console.log(index,tax);

    }

    if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
        this.billrefno="A"+doc.data().countG;
    })
    if(this.taxy.toUpperCase().slice(0, 1)=="T")
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
        this.billrefno="B"+doc.data().countR;
    })
}

public BPSForm: FormGroup;
bcid : any;
billid : any;

billrefno : any;

//qntycounters
qntycounters: { bcid: string, qntycount: number }[]= [] //declare before constructor
//qntycounters

groupedData: any = [];

myArray: {amtaftdisc: number, gstp: number, cgstp: number, cgsta: number, sgstp: number, sgsta: number, igstp: number, igsta: number, taxableamt: number, rate: number, prate: number }[]= [] //declare before constructor

date2= moment().format('DD-MM-YYYY');
currentbillid:any;
routinebillid: any;

cashpay:number=0;
cardpay:number=0;
upipay:number=0;
rempay:number=0;

custcollection: any;
cust: any;
ar: number=0;
constructor(private afs: AngularFirestore, public fb: FormBuilder) {
  
  this.favoriteCustomer=this.customers[0]; // set default radio button checked
  this.billoc=this.billocations[0]; // set default radio button checked
  this.taxy='gst' // set default radio button checked
  this.cbilly='no' // set default radio button checked
   //this.modeofpay='CASH'; // set default radio button checked
   this.modeofpay=''; // set default radio button checked
   this.cashpay=0;
   this.cardpay=0;
   this.upipay=0;
   this.rempay=0;
 }

 ngAfterViewInit() {
    var el =document.getElementsByClassName('rightsidebar') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="max-width: 950px !IMPORTANT; width: 950px !IMPORTANT;"
    console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ")
    this.eventdate=moment().format('DD-MM-YYYY')

}
  async ngOnInit() {
    //newly added
    this.ar=0;
    this.sumaftrdisc=0.00;
    this.totamtwithtax=0.00;
    this.cbilly='no';
   // this.modeofpay='CASH'; 
   this.modeofpay=''; // set default radio button checked
    this.tottaxableamt=0.00;
    this.mandisc=0;
    this.ocharges=0;

//newly added

    this.btnsdisabled();

    this.sameaddr=true;

  this.custid='';
  this.custname='';
  this.custimage='';
  this.custtype='';
  this.custdiscounttype='';
  this.custaddress11='';
  this.custaddress12='';
  this.custaddress21='';
  this.custaddress22='';
  this.custcontact='';
  this.custgst='';
  this.custhonor='';
  this.custaddressS1='';
  this.custaddressS2='';

  this.Form();

  // this.custcollection = this.afs.collection('Customers',ref=> ref.orderBy('customerName')).snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map(a => a.payload.doc.data()))
  //   );

  
  this.tottaxableamt=0;
  this.totamtwithtax=0;

  this.sumaftrdisc=0;
  this.sumprateaftrqnty=0;
  this.totalamount=0;
  this.totalpamount=0;

//   this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
//     this.billrefno=doc.data().count;
// })

if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.billrefno="A"+doc.data().countG;
  })
  if(this.taxy.toUpperCase().slice(0, 1)=="T")
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.billrefno="B"+doc.data().countR;
  })


let document = await firebase.firestore().collection("Bills").doc(this.date2).get();
if (document && document.exists) {
  // await document.ref.update({
  //   updated: new Date().toISOString()
  // });
}
else {
  await document.ref.set({
    currbillid : 1,
    currbillfullid : this.billoc.toUpperCase().slice(0,1)+this.billoc.toUpperCase().slice(-1)+this.favoriteCustomer.toUpperCase().slice(0,1)+this.taxy.toUpperCase().slice(0,1)+'1',
    //currbillfullid:this.hello,
    totalbills : 0,
    routebillid: 1,
    date2 : this.date2,
  }, { merge: true });
  
}




this.crcollection = this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).snapshotChanges()
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);

this.crcollection.subscribe(() => {
  this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
      this.currentbillid = docy.data().currbillid;
    })
  })

  //newly added
  if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
        this.billrefno="A"+doc.data().countG;
    })
    if(this.taxy.toUpperCase().slice(0, 1)=="T")
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
        this.billrefno="B"+doc.data().countR;
    })
  //newly added
  
})


this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(docy => {
    this.currentbillid = docy.data().currbillid;
    this.routinebillid = docy.data().routebillid;
    this.afs.collection('Bills').doc(this.date2).set(
      {
        routebillid: this.routinebillid + 1,
      },
      { merge: true }
    )
  })
}).then(()=>{
//newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added
  })
.then(()=>{

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.routinebillid)).set(
    {
      currentbillid: this.currentbillid,
      currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
      //currbillfullid:this.hello,
      routinebillid:this.routinebillid,
      location: this.billoc,
      status:'created'
    },
     { merge: true }
  )
  console.log("aaaaaaaa");

}).then(()=>{

console.log("bbbroutineID"+this.routinebillid)
this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
querySnapshot.forEach(doc => {

this.currentbillid=doc.data().currentbillid;
console.log(":::::::::::::::::::::::currbillid::::::"+this.currentbillid);

// if(String(doc.data().currbillfullid).slice(0,2)=="S1"){
// this.billoc="shop1";
// }
// else if(String(doc.data().currbillfullid).slice(0,2)=="S2"){
//   this.billoc="shop2";
//   }
//   else if(String(doc.data().currbillfullid).slice(0,2)=="GN"){
//     this.billoc="Godown";
//     }

//     if(String(doc.data().currbillfullid).slice(2,3)=="W"){
//       this.favoriteCustomer="wholesale";
//       }
//       else if(String(doc.data().currbillfullid).slice(2,3)=="P"){
//         this.favoriteCustomer="prime";
//         }
//         else if(String(doc.data().currbillfullid).slice(2,3)=="I"){
//           this.favoriteCustomer="institute";
//           }
//           else if(String(doc.data().currbillfullid).slice(2,3)=="R"){
//             this.favoriteCustomer="regular";
//             }

//             if(String(doc.data().currbillfullid).slice(3,4)=="G"){
//               this.taxy="gst";
//               this.TaxnameGST='gst';this.TaxnameIGST='0';this.TaxnameTF='0';
//               }
//               else if(String(doc.data().currbillfullid).slice(3,4)=="I"){
//                 this.taxy="igst";
//                 this.TaxnameGST='0';this.TaxnameIGST='igst';this.TaxnameTF='0';
//                 }
//                 else if(String(doc.data().currbillfullid).slice(3,4)=="T"){
//                   this.taxy="taxfree";
//                   this.TaxnameGST='0';this.TaxnameIGST='0';this.TaxnameTF='taxfree';
//                   }

//                    console.log("hellolo"+String(this.routinebillid))




this.prcollection = this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).snapshotChanges()
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  this.prcollection.subscribe((posts) => {

    console.log("posty:"+posts); //This is the array of posts, not an observable

    if(this.TaxnameGST != '0'){
    this.summy=_(posts).groupBy('cgst')
      .map((objs, key) => {
          return {
              'cgst': key,
              // 'rate': _.sumBy(objs, 'rate') * _.maxBy(objs, 'qnty'),
              
        'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
        'gstp':(key*2),
        'cgstp':(parseFloat(key)),
        'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'sgstp':(parseFloat(key)),
        'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'igstp':(parseFloat('0')),
        'igsta':(parseFloat('0.00')),
        'taxableamt':2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'prateaftqnty': (_.sumBy(objs, 'purchaserateafterqnty')),
          }
      })
      .value();

      this.tottaxableamt=this.summy.reduce((sum, item) => sum + item.taxableamt, 0)
      this.totamtwithtax=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0) + this.tottaxableamt;

      this.sumaftrdisc=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0)
      this.sumprateaftrqnty=this.summy.reduce((sum, item) => sum + item.prateaftqnty, 0)
      console.log("SUMMYYY:"+JSON.stringify(this.summy));
    }

    if(this.TaxnameIGST != '0'){
      this.summy=_(posts).groupBy('igst')
        .map((objs, key) => {
            return {
                'igst': key,
                // 'rate': _.sumBy(objs, 'rate') * _.maxBy(objs, 'qnty'),
                
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'gstp':(0),
                'cgstp':(parseFloat('0')),
                'cgsta':(parseFloat('0.00')),
                'sgstp':(parseFloat('0')),
                'sgsta':(parseFloat('0.00')),
                'igstp':(parseFloat(key)),
                'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                'prateaftqnty': (_.sumBy(objs, 'purchaserateafterqnty')),
                 }
        })
        .value();

        this.tottaxableamt=this.summy.reduce((sum, item) => sum + item.taxableamt, 0)
        this.totamtwithtax=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0) + this.tottaxableamt;

this.sumaftrdisc=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0);
this.sumprateaftrqnty=this.summy.reduce((sum, item) => sum + item.prateaftqnty, 0)
        console.log("SUMMYYY:"+JSON.stringify(this.summy));
      }


      if(this.TaxnameTF != '0'){
        this.summy=_(posts).groupBy('taxfree')
          .map((objs, key) => {
              return {
                  'taxfree': key,
                  // 'rate': _.sumBy(objs, 'rate') * _.maxBy(objs, 'qnty'),
                  
                  'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                  'gstp':(0),
                  'cgstp':(parseFloat('0')),
                  'cgsta':(parseFloat('0.00')),
                  'sgstp':(parseFloat('0')),
                  'sgsta':(parseFloat('0.00')),
                  'igstp':(parseFloat('0.00')),
                  'igsta':(parseFloat('0.00')),
                  'taxableamt':(parseFloat('0.00')),
                  'prateaftqnty': (_.sumBy(objs, 'purchaserateafterqnty')),
                   }
          })
          .value();
  
          this.tottaxableamt=this.summy.reduce((sum, item) => sum + item.taxableamt, 0)
          this.totamtwithtax=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0) + this.tottaxableamt;

          this.sumaftrdisc=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0)
          this.sumprateaftrqnty=this.summy.reduce((sum, item) => sum + item.prateaftqnty, 0)
          console.log("SUMMYYY:"+JSON.stringify(this.summy));
        }


    console.log("posts:"+posts); //This is the array of posts, not an observable

    let ratetotal = 0;
    let pratetotal = 0;
    let amounttotal = 0;
    let pamounttotal = 0;
  console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts.length);
  for (var i = 0; i < posts.length; i++) {
      if (posts[i].rate) {
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].rate);
          ratetotal += posts[i].rate;
          this.totalrate = ratetotal;

          amounttotal += (posts[i].rate * posts[i].qnty);
          this.totalamount = amounttotal;
      }
      if (posts[i].prate) {
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].prate);
          pratetotal += posts[i].prate;
          this.totalprate = pratetotal;

          pamounttotal += (posts[i].prate * posts[i].qnty);
          this.totalpamount = pamounttotal;
      }
  }
  console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+ratetotal);
  return [ratetotal,amounttotal,pratetotal,pamounttotal];

  });

})
}).then(()=>{

this.value = 100; //progressbar
this.delay(1000).then(any=>{
  this.value = 0; //progressbar

    this.cleartable();
    this.btnsenabled();
});
})
})

}


// changeCustomer(ct){
//   this.custid=ct.KEYID;
//   this.custname=ct.customerName;
//   this.custaddress11=ct.customerAddress11;
//   this.custaddress12=ct.customerAddress12;
//   this.custaddress21=ct.customerAddress21;
//   this.custaddress22=ct.customerAddress22;
//   this.custcontact=ct.customerContact;
//   this.custgst=ct.customerGst;
//   this.custhonor=ct.customerHonor;
//   this.sameaddr=true;
//   console.log("SA"+this.sameaddr)
//   this.custaddressS1=this.custaddress11;
//   this.custaddressS2=this.custaddress12;
// }

Csearch(ph){
  this.afs.collection('Customers', ref => ref.where('customerContact', '==' , String(ph))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
  this.custid=docy.data().KEYID;
  this.custname=docy.data().customerName;
  this.custimage=docy.data().customerImage;
  this.custtype=docy.data().customerType;
  this.custdiscounttype=docy.data().customerDiscountType;
  this.custaddress11=docy.data().customerAddress11;
  this.custaddress12=docy.data().customerAddress12;
  this.custaddress21=docy.data().customerAddress21;
  this.custaddress22=docy.data().customerAddress22;
  this.custcontact=docy.data().customerContact;
  this.custgst=docy.data().customerGst;
  this.custhonor=docy.data().customerHonor;
  this.sameaddr=true;
  console.log("SA"+this.sameaddr)
  this.custaddressS1=this.custaddress11;
  this.custaddressS2=this.custaddress12;
  this.favoriteCustomer= (docy.data().customerDiscountType != undefined) ? docy.data().customerDiscountType : 'regular';
  console.log("FC"+this.favoriteCustomer);
    })
  });
}

shipaddress(event){
console.log(event.checked);
if(event.checked==true){
  this.custaddressS1=this.custaddress11;
  this.custaddressS2=this.custaddress12;
}
if(event.checked==false){
  this.custaddressS1=this.custaddress21;
  this.custaddressS2=this.custaddress22;
}
}

updateradiobuttons(){
  this.afs.collection('Bills', ref => ref.where('date2', '==' , this.date2)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
this.currentbillid=docy.data().currbillid;
  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.routinebillid)).update({
    currentbillid: this.currentbillid,
    currbillfullid: this.billoc.toUpperCase().slice(0,1)+this.billoc.toUpperCase().slice(-1)+this.favoriteCustomer.toUpperCase().slice(0,1)+this.taxy.toUpperCase().slice(0,1)+docy.data().currbillid,
    //currbillfullid:this.hello,
    
  })
})
  })
  //newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added
}

updateBillCheckedOptions(i, billocation)
{
  this.updateradiobuttons();
}
updateCustCheckedOptions(i, customer)
{
  this.updateradiobuttons();
}

  // Reactive particular form
  Form() {
    this.BPSForm = this.fb.group({
      barcodeid: [''],
      credit: [0],
      debit: [0]
    })
  }
  // Accessing form control using getters
  get barcodeid() {
    return this.BPSForm.get('barcodeid');
  }
  get credit() {
    return this.BPSForm.get('credit');
  }
  get debit() {
    return this.BPSForm.get('debit');
  }

  prcollection: any;

  btnsdisabled(){
  this.bcbtndisabled=true;
  this.clrbtndisabled=true;
  this.addbtndisabled=true;
  this.rembtndisabled=true;
}
 btnsenabled(){
  this.bcbtndisabled=false;
  this.clrbtndisabled=false;
  this.addbtndisabled=false; 
  this.rembtndisabled=false;
  
}
  BCsearch(sid) {  
    if(this.bcid){
    this.btnsdisabled();
        this.hello=document.getElementById('billidlabel').textContent;
console.log(this.hello);

  this.value = 15; //progressbar
  
  let countUpdated = {'bcid': this.bcid, 'qntycount':0};

  this.afs.collection('Products', ref => ref.where('code', '==' , this.bcid)).get().toPromise().then(querySnapshot => {
  if (querySnapshot.size > 0) {
console.log("querysnapshotsize: "+querySnapshot.size);
      querySnapshot.forEach(doc => {
        if (doc.data().code == this.bcid) {

        console.log(doc.data().code);
        console.log(this.TaxnameGST);
 
        if(!this.qntycounters.find(item => item.bcid == countUpdated.bcid)){
        //qntycounters insert
        this.qntycounters.push({'bcid':this.bcid, qntycount: doc.data().unit1});
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters insert
          }
        else {
        //qntycounters update
        this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount = this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount+ doc.data().unit1;
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters update
        }

        
         
  
  
        this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(docy => {
   // this.currentbillid=docy.data().currentbillid;
   // console.log("currentbillid::::::"+this.currentbillid);

        this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(doc.data().code).set(
          {
            KEYID: doc.data().KEYID,
            code : doc.data().code,
            // productname : doc.data().productname,
            productname : doc.data().brand +' '+ doc.data().category,
            hsncode : doc.data().hsncode,
            unit1 : doc.data().unit1, 
            unit2 : doc.data().unit2,
            rate : doc.data().rate,
            prate : (doc.data().prate != undefined) ? doc.data().prate : 0,
            qnty : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount, //qnty
            //discount : doc.data()[this.favoriteCustomer],
            discount : doc.data().discount[this.favoriteCustomer],
            // cgst : doc.data()[this.TaxnameCGST],
            // sgst : doc.data()[this.TaxnameSGST],
            // igst : doc.data()[this.TaxnameIGST],

            cgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            sgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            igst : doc.data()[this.TaxnameIGST],
            amountafterdiscount : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100))),
            purchaserateafterqnty:
            (doc.data().prate != undefined)
            ? this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * doc.data().prate : 0
          },
           {merge: true}
      ).then(()=>{
        //this.sumtablecolumn();

        this.prdkeyid = doc.data().KEYID;
        console.log(this.prdkeyid)

        if (this.billoc == 'shop1') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop1: doc.data().qntyshop1 - 1,
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'shop2') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop2: doc.data().qntyshop2 - 1,
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'Godown') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntygodown: doc.data().qntygodown - 1,
          })
          this.btnsenabled();
        }

      })
        .catch((err) => {
          console.log(err);
        })

})
})

      }
      })
    

    } else {
      console.log("No such document!");
      this.btnsenabled();
    }

  })
 
  .catch(function(error) {
    console.log("Error getting document: ", error);
  })
  
  .then(()=>{
    
//---newly added-----
this.bcid='';
//---newly added-----

    this.bufferValue=100;
    
      this.value = 100; //progressbar
      this.delay(1000).then(any=>{
        this.value = 0; //progressbar
    });})


    this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.routinebillid)).set(
      {
        currentbillid: this.currentbillid,
        currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
        //currbillfullid:this.hello,
        routinebillid:this.routinebillid,
        location: this.billoc,
        status:'using'
      },
       { merge: true }
    )
    console.log("aaaaaaaa");
    }
    
  }
 async delay(ms: number) {
  await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>{console.log("fired");});
}

upcount(bcode){
  this.btnsdisabled();

  let countUpdated = {'bcid': bcode, 'qntycount':0};
  this.value = 10; //progressbar
  this.afs.collection('Products', ref => ref.where('code', '==' , bcode)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == bcode) {

        console.log(doc.data().code);
        console.log(this.TaxnameGST);
 

        if(!this.qntycounters.find(item => item.bcid == countUpdated.bcid)){
        //qntycounters insert
        this.qntycounters.push({'bcid':bcode, qntycount: doc.data().unit1});
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters insert
          }
        else {
        //qntycounters update
        this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount = this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount+ doc.data().unit1;
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters update
        }
        
        console.log("RBID "+this.routinebillid);
        this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(docy => {
    //this.currentbillid=docy.data().currbillid;
    //console.log("currbillid::::::"+this.currentbillid);
    
        this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(doc.data().code).set(
          {
            KEYID: doc.data().KEYID,
            code : doc.data().code,
            // productname : doc.data().productname,
            productname : doc.data().brand +' '+ doc.data().category,
            hsncode : doc.data().hsncode,
            unit1 : doc.data().unit1, 
            unit2 : doc.data().unit2,
            rate : doc.data().rate,
            prate : (doc.data().prate != undefined) ? doc.data().prate : 0,
            qnty : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount, //qnty
            //discount : doc.data()[this.favoriteCustomer],
            discount : doc.data().discount[this.favoriteCustomer],
            // cgst : doc.data()[this.TaxnameCGST],
            // sgst : doc.data()[this.TaxnameSGST],
            // igst : doc.data()[this.TaxnameIGST],

            cgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            sgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            igst : doc.data()[this.TaxnameIGST],
            amountafterdiscount : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100))),
            purchaserateafterqnty:
            (doc.data().prate != undefined)
            ? this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * doc.data().prate : 0
          },
           {merge: true}
      ).then(()=>{
        this.prdkeyid = doc.data().KEYID;
        console.log(this.prdkeyid)

        if (this.billoc == 'shop1') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop1: doc.data().qntyshop1 - 1,
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'shop2') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop2: doc.data().qntyshop2 - 1,
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'Godown') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntygodown: doc.data().qntygodown - 1,
          })
          this.btnsenabled();
        }

      })
        .catch((err) => {
          console.log(err);
        })

      })
    })

      }
      })
    

    // } else {
    //   console.log("No such document!");
    // }

  })
  .catch(function(error) {
    console.log("Error getting document: ", error);
  });

  this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});

}


downcount(bcode){
  this.btnsdisabled();

  let countUpdated = {'bcid': bcode, 'qntycount':0};
  this.value = 10; //progressbar

  this.afs.collection('Products', ref => ref.where('code', '==' , bcode)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == bcode) {

        console.log(doc.data().code);
        console.log(this.TaxnameGST);
 

        if(!this.qntycounters.find(item => item.bcid == countUpdated.bcid)){
        //qntycounters insert
        this.qntycounters.push({'bcid':bcode, qntycount: doc.data().unit1});
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters insert
          }
        else {
        //qntycounters update
        this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount = this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount- doc.data().unit1;
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters update
        }
        

        
        this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(docy => {
   // this.currentbillid=docy.data().currbillid;
   // console.log("currbillid::::::"+this.currentbillid);

        this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(doc.data().code).set(
          {
            KEYID: doc.data().KEYID,
            code : doc.data().code,
            // productname : doc.data().productname,
            productname : doc.data().brand +' '+ doc.data().category,
            hsncode : doc.data().hsncode,
            unit1 : doc.data().unit1, 
            unit2 : doc.data().unit2,
            rate : doc.data().rate,
            prate : (doc.data().prate != undefined) ? doc.data().prate : 0,
            qnty : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount, //qnty
            //discount : doc.data()[this.favoriteCustomer],
            discount : doc.data().discount[this.favoriteCustomer],
            // cgst : doc.data()[this.TaxnameCGST],
            // sgst : doc.data()[this.TaxnameSGST],
            // igst : doc.data()[this.TaxnameIGST],

            cgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            sgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            igst : doc.data()[this.TaxnameIGST],
            amountafterdiscount : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100))),
            purchaserateafterqnty:
            (doc.data().prate != undefined)
            ? this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * doc.data().prate : 0
          },
           {merge: true}
      ).then(()=>{
        this.prdkeyid = doc.data().KEYID;
        console.log(this.prdkeyid)

        if (this.billoc == 'shop1') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop1: doc.data().qntyshop1 + 1,
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'shop2') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop2: doc.data().qntyshop2 + 1,
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'Godown') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntygodown: doc.data().qntygodown + 1,
          })
          this.btnsenabled();
        }

      })
        .catch((err) => {
          console.log(err);
        })

      })
    })

      }
      })
    

    // } else {
    //   console.log("No such document!");
    // }

  })
  .catch(function(error) {
    console.log("Error getting document: ", error);
  });

  this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});

}

customqnty(bcode,cstmqnty){
 
  this.btnsdisabled();

  let countUpdated = {'bcid': bcode, 'qntycount':0};
  this.value = 10; //progressbar
  this.afs.collection('Products', ref => ref.where('code', '==' , bcode)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == bcode) {

        console.log(doc.data().code);
        console.log(this.TaxnameGST);
 

        if(!this.qntycounters.find(item => item.bcid == countUpdated.bcid)){
        //qntycounters insert
        this.qntycounters.push({'bcid':bcode, qntycount: Number(cstmqnty)});
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters insert
          }
        else {
        //qntycounters update
        this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount = this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount+ (Number(cstmqnty));
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters update
        }
        
        console.log("RBID "+this.routinebillid);
        this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(docy => {
    //this.currentbillid=docy.data().currbillid;
    //console.log("currbillid::::::"+this.currentbillid);
    
        this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(doc.data().code).set(
          {
            KEYID: doc.data().KEYID,
            code : doc.data().code,
            // productname : doc.data().productname,
            productname : doc.data().brand +' '+ doc.data().category,
            hsncode : doc.data().hsncode,
            unit1 : Number(cstmqnty), 
            unit2 : doc.data().unit2,
            rate : doc.data().rate,
            prate : (doc.data().prate != undefined) ? doc.data().prate : 0,
            qnty : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount, //qnty
            //discount : doc.data()[this.favoriteCustomer],
            discount : doc.data().discount[this.favoriteCustomer],
            // cgst : doc.data()[this.TaxnameCGST],
            // sgst : doc.data()[this.TaxnameSGST],
            // igst : doc.data()[this.TaxnameIGST],

            cgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            sgst : parseFloat(doc.data()[this.TaxnameGST])/2,
            igst : doc.data()[this.TaxnameIGST],
            amountafterdiscount : this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100))),
            purchaserateafterqnty:
            (doc.data().prate != undefined)
            ? this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount * doc.data().prate : 0
          },
           {merge: true}
      ).then(()=>{
        this.prdkeyid = doc.data().KEYID;
        console.log(this.prdkeyid)

        if (this.billoc == 'shop1') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop1: doc.data().qntyshop1 - Number(cstmqnty),
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'shop2') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop2: doc.data().qntyshop2 - Number(cstmqnty),
          })
          this.btnsenabled();
        }
        else if (this.billoc == 'Godown') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntygodown: doc.data().qntygodown - Number(cstmqnty),
          })
          this.btnsenabled();
        }

      })
        .catch((err) => {
          console.log(err);
        })

      })
    })

      }
      })
    

    // } else {
    //   console.log("No such document!");
    // }

  })
  .catch(function(error) {
    console.log("Error getting document: ", error);
  });

  this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});

}




  remove(bcode){
  this.btnsdisabled();

  let countUpdated = {'bcid': bcode, 'qntycount':0};

  this.value = 10; //progressbar

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach( docy => {
//this.currentbillid=docy.data().currbillid;
//console.log("currbillid::::::"+this.currentbillid);

 this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(bcode).get().toPromise().then( doc => {

  this.billqnty=doc.data().qnty;
this.prdkeyid=doc.data().KEYID;

        if (this.billoc == 'shop1') {
           this.afs.collection('Products').doc(this.prdkeyid).get().toPromise().then(doc => {
            this.prdqnty=doc.data().qntyshop1+this.billqnty
          }).then(()=>{
          this.afs.collection('Products').doc(this.prdkeyid).update({
            qntyshop1: this.prdqnty
          })
        })
          this.btnsenabled();
        }
        else if (this.billoc == 'shop2') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).get().toPromise().then(doc => {
            this.prdqnty=doc.data().qntyshop2+this.billqnty
          }).then(()=>{
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntyshop2: this.prdqnty
          })
        })
          this.btnsenabled();
        }
        else if (this.billoc == 'Godown') {
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).get().toPromise().then(doc => {
            this.prdqnty=doc.data().qntygodown+this.billqnty
          }).then(()=>{
          this.afs.collection('Products', ref => ref.where('code', '==', this.bcid)).doc(this.prdkeyid).update({
            qntygodown: this.prdqnty
          })
        })
          this.btnsenabled();
        }
}).then(()=>{
    this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(bcode).delete().then(function() {

    console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
})

})
})

  this.afs.collection('Products', ref => ref.where('code', '==' , bcode)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == bcode) {

        console.log(doc.data().code);
        console.log(this.TaxnameGST);
 

        if(!this.qntycounters.find(item => item.bcid == countUpdated.bcid)){
        //qntycounters insert
        this.qntycounters.push({'bcid':bcode, qntycount: doc.data().unit1});
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters insert
          }
        else {
        //qntycounters update to 0 //delete
        this.qntycounters.find(item => item.bcid == countUpdated.bcid).qntycount = 0;
        console.log(JSON.stringify(this.qntycounters));
        //qntycounters update to 0 //delete
        }
      }
  })
})

this.value = 100; //progressbar
this.delay(1000).then(any=>{
  this.value = 0; //progressbar
});

}

// sumtablecolumn(){
//   var table: HTMLTableElement = <HTMLTableElement> document.getElementById("mytable");
//   var rowslength = table.rows.length;
//   console.log(rowslength);
//   var columnslength = table.rows[2].cells.length // 0 and 1 are header
//   console.log(columnslength);

//   let sum:number=0;
//   for(var i=2; i<rowslength-1; i++){
//     for (var j=4; j<columnslength;j++){
    
//     }
//     console.log("val:"+table.rows[i].cells[12].innerHTML);
//     sum= sum + parseFloat(table.rows[i].cells[12].innerHTML.replace(/,/g,""));//remoes comma
//       console.log("SUM:"+Number(sum).toFixed(2)); 
//   }
// }

// sumtablecolumn(){
//   var table: HTMLTableElement = <HTMLTableElement> document.getElementById("mytable");
//   var rowslength = table.rows.length;
//   console.log(rowslength);
//   var columnslength = table.rows[2].cells.length // 0 and 1 are header
//   console.log(columnslength);
//   this.sumaftrdisc=0;
//   for(var i=2; i<rowslength-1; i++){
//     for (var j=4; j<columnslength;j++){
    
//     }
//     console.log("val:"+table.rows[i].cells[8].innerHTML);
//     this.sumaftrdisc= this.sumaftrdisc + parseFloat(table.rows[i].cells[8].innerHTML.replace(/,/g,""));//remoes comma
//     this.sumaftrdisc=Number((this.sumaftrdisc).toFixed(2); 
//   }
// }

cleartable(){
  this.qntycounters=[];

  this.btnsdisabled();
  this.value = 10; //progressbar

  this.arry=[];
  var table: HTMLTableElement = <HTMLTableElement> document.getElementById("mytable");
  var rowslength = table.rows.length;
  console.log(rowslength);
  var columnslength = table.rows[2].cells.length // 0 and 1 are header
  console.log(columnslength);

  let sum:number=0;
  for(var i=2; i<rowslength-1; i++){
    
    console.log("qnty:"+table.rows[i].cells[3].innerText.match(/\d/g).join('')); //removes text
    console.log("code:"+table.rows[i].cells[1].innerText); 
this.arry.push({'code':table.rows[i].cells[1].innerText, 'qnty':Number(table.rows[i].cells[3].innerText.match(/\d/g).join(''))});
console.log(this.arry);
  }
// this.arry.forEach((element)=>{
//   let elem = document.getElementById(element.code);
//   let evt= new MouseEvent('click',{
//     bubbles:true,
//     cancelable:true,
//     view:window
  
//   });
//   elem.dispatchEvent(evt);
//   console.log("event called"+element.code)
// })

  

console.log("LOOOP:"+i);
for(let lo=0;lo<=rowslength-4;lo++){
  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach( docy => {
this.currentbillid=docy.data().currentbillid;
console.log("currentbillid::::::"+this.currentbillid);
this.prdkeyidarray=[];


//newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added


// this.billqntyarray=[];
// for(let y=0;y<this.prdkeyidarray.length;y++){
// this.afs.collection('Products').doc(String(this.prdkeyidarray[y]))
//             .get().toPromise().then(doc => {
// this.billqntyarray.push(doc.data().qntyshop1);
// console.log("ARRRRRY"+this.billqntyarray)
//             })
//       }
      
  this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(this.arry[lo].code).get().toPromise().then( doc => {

  this.billqnty=doc.data().qnty;
  this.prdkeyid=doc.data().KEYID;
this.prdkeyidarray.push(doc.data().KEYID);
console.log("HELLLO"+this.prdkeyidarray.length)


        if (this.billoc == 'shop1') {
            // this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyid))
            // .get().toPromise().then(function(querySnapshot){
            //   querySnapshot.forEach(function(doc) {
            //     var prdref= this.afs.collection('Products').doc(this.prdkeyid);
            //     prdref.set({
            //       qntyshop1: doc.data().qntyshop1+this.arry[lo].qnty
            //     },{merge:true});
            //   });
            // });

//               const newDocumentBody={
//   qntyshop1: this.arry[lo].qnty
// }

//             this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyid))
//             .get().toPromise().then(response => {
//               let batch=this.afs.firestore.batch()
//               response.docs.forEach((doc) => {
// for(let z=0;z<this.prdkeyidarray.length;z++){
//   const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
//   batch.update(docRef,{'qntyshop1': doc.data().qntyshop1+this.arry[z].qnty})
//   // const docRef1 = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[1]))
//   // batch.update(docRef1,{'qntyshop1': doc.data().qntyshop1+this.arry[1].qnty})
// }                 
// })
// batch.commit().then(()=>{
//   console.log("updated"+this.prdkeyid)
// })
//  });
       

for(let z=0;z<this.prdkeyidarray.length;z++){

  this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyidarray[z]))
  .get().toPromise().then(response => {
    let batch=this.afs.firestore.batch()
    response.docs.forEach((doc) => {
console.log("GGGGGGGG"+doc.data().qntyshop1);
const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
batch.update(docRef,{'qntyshop1': doc.data().qntyshop1+this.arry[z].qnty})
// const docRef1 = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[1]))
// batch.update(docRef1,{'qntyshop1': doc.data().qntyshop1+this.arry[1].qnty})
      
})
batch.commit().then(()=>{
console.log("updated"+this.prdkeyid)
})
  
});
}

    this.btnsenabled();
  }
        else if (this.billoc == 'shop2') {
          
for(let z=0;z<this.prdkeyidarray.length;z++){

  this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyidarray[z]))
  .get().toPromise().then(response => {
    let batch=this.afs.firestore.batch()
    response.docs.forEach((doc) => {
console.log("GGGGGGGG"+doc.data().qntyshop2);
const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
batch.update(docRef,{'qntyshop2': doc.data().qntyshop2+this.arry[z].qnty})
      
})
batch.commit().then(()=>{
console.log("updated"+this.prdkeyid)
})
  
});
}

    this.btnsenabled();
        }

        else if (this.billoc == 'Godown') {          
          for(let z=0;z<this.prdkeyidarray.length;z++){
          
            this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyidarray[z]))
            .get().toPromise().then(response => {
              let batch=this.afs.firestore.batch()
              response.docs.forEach((doc) => {
          console.log("GGGGGGGG"+doc.data().qntygodown);
          const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
          batch.update(docRef,{'qntygodown': doc.data().qntygodown+this.arry[z].qnty})
                
          })
          batch.commit().then(()=>{
          console.log("updated"+this.prdkeyid)
          })
            
          });
          }
          
              this.btnsenabled();
        }
}).then(()=>{
  for(let x=0;x<this.arry.length;x++){
    this.afs.collection('Bills').doc(this.date2).collection(String(this.routinebillid)).doc(String(this.arry[x].code)).delete().then(function() {
    console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
  }
})

})
})


this.value = 100; //progressbar
this.delay(1000).then(any=>{
  this.value = 0; //progressbar
});


}

}


submitbtn(){
  //------newly added------
this.btnsdisabled();
//------newly added------

  this.hello=document.getElementById('billidlabel').textContent;
  this.qntycounters=[];
console.log("helo"+this.hello)
  this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
this.totbills=docy.data().totalbills;
  this.afs.collection('Bills').doc(this.date2).set(
    {
      totalbills : this.totbills + 1,
      currbillid : this.currentbillid + 1,
      billsid:firebase.firestore.FieldValue.arrayUnion(this.hello),
      billsrefno:firebase.firestore.FieldValue.arrayUnion(this.billrefno)
    },
    {merge: true}
 )

  })
  
})
.then(()=>{

  this.afs.collection('Bills').doc(this.date2).collection('--DISCOUNTS--').doc(String(this.currentbillid)).set(
    {
      billid:this.hello,
      manualdisc:this.mandisc, 
    },
     {merge: true}
  )
})

.then(()=>{

  this.afs.collection('Bills').doc(this.date2).collection('--OTHERCHARGES--').doc(String(this.currentbillid)).set(
    {
      billid:this.hello,
      othercharges:this.ocharges,
    },
     {merge: true}
  )
})

.then(() => {
  //   this.afs.collection('OverallBillCounter').doc('Counter').set(
  //     {
  //       count : firebase.firestore.FieldValue.increment(1),
  //     },
  //      {merge: true}
  // )
  
  if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I"){
    this.afs.collection('OverallBillCounter').doc('Counter').set(
      {
        countG : firebase.firestore.FieldValue.increment(1),
      },
       {merge: true}
  )

    }
    if(this.taxy.toUpperCase().slice(0, 1)=="T"){
      this.afs.collection('OverallBillCounter').doc('Counter').set(
        {
          countR : firebase.firestore.FieldValue.increment(1),
        },
         {merge: true}
    )
      }
      
  })
  .then(()=>{
//newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added
}).then(()=>{
  
  this.afs.collection('Bills').doc(this.date2).collection('--Id--').doc(String(this.currentbillid)).set(
    {
      currentbillid: this.currentbillid,
      //currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
      currbillfullid:this.hello,
      routinebillid:this.routinebillid,
      location: this.billoc,
      paymentmode: this.modeofpay,
      cashpay:this.cashpay,
      cardpay:this.cardpay,
      upipay:this.upipay,
      amountpaid: Number((this.sumaftrdisc + this.tottaxableamt - this.mandisc + this.ocharges).toFixed(0)),
      status:'completed'
    },
     { merge: true }
  )
  console.log("aaaaaaaa");

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.routinebillid)).set(
    {
      currentbillid: this.currentbillid,
      //currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
      currbillfullid:this.hello,
      routinebillid:this.routinebillid,
      status:'completed'
    },
     { merge: true }
  )

})


.then(() => {
  this.afs.collection('Bills').doc(this.date2).collection('--stats--').doc(String(this.currentbillid)).set(
    {
      // billid: this.currentbillid,
      // taxtype : this.taxy,
      // location: this.billoc,
      // customertype: this.favoriteCustomer
      customerId:this.custid,
      customerHonor:this.custhonor,
      customerName:this.custname,
      customerImage:this.custimage,
      customerType:this.custtype,
      customerDiscountType:this.custdiscounttype,
      customerAddress11:this.custaddress11,
      customerAddress12:this.custaddress12,
      customerAddress21:this.custaddressS1,
      customerAddress22:this.custaddressS2,
      customerContact:this.custcontact,
      customerGst:this.custgst,
      billrefno:this.billrefno,
      paymentmode: this.modeofpay,
      cashpay:this.cashpay,
      cardpay:this.cardpay,
      upipay:this.upipay,
      cbill: this.cbilly,
    },
     {merge: true}
  )
})

.then(() => {
  if(this.crd !=0 || this.dbt != 0){
    let ids =this.afs.createId();
this.afs.collection("Ledger").doc(ids).set({
  book: "SALES",
  KEYID: ids,
  date: firebase.firestore.FieldValue.serverTimestamp(),
  date2: this.date2,
  part:this.custid+"+"+this.custname+"+customer",
  particulartype:"customer",
  // cust: {
  //   customerId:this.custid,
  //   customerHonor:this.custhonor,
  //   customerName:this.custname,
  //   customerAddress11:this.custaddress11,
  //   customerAddress12:this.custaddress12,
  //   customerAddress21:this.custaddressS1,
  //   customerAddress22:this.custaddressS2,
  //   customerContact:this.custcontact,
  //   customerGst:this.custgst,
  //   billrefno:this.billrefno
  // },
  debit: this.dbt,
  credit: this.crd,
  description: this.billrefno+ "/" +this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
  tag: "BILLS",
  name: this.custname
})
  .catch((err) => {
    console.log(err);
  })
}
})

.then(() => {

this.afs.collection('Bills').doc(this.date2).collection('--stats--').doc('sjr6Ao1emuzGe41CzdJJ').set(
  {
    maxidcounter : firebase.firestore.FieldValue.increment(1),
  },
   {merge: true}
).then(() => {
this.ngOnInit();
})
})
//------newly added------
this.prcollection='';
this.totalamount=0.00;
this.totalpamount=0.00;
// this.sumaftrdisc=0.00;
// this.totamtwithtax=0.00;
this.summy=[];
// this.tottaxableamt=0.00;
// this.cust='';
// this.custhonor='';
// this.custname='';
// this.custaddress11='';
// this.custaddress12='';
// this.custaddressS1='';
// this.custaddressS2='';
// this.custcontact='';
// this.custgst='';
  this.favoriteCustomer=this.customers[0]; // set default radio button checked
  this.taxy='gst' // set default radio button checked
  //this.cbilly='no' // set default radio button checked
  //this.modeofpay='CASH'; // set default radio button checked
this.btnsdisabled();

//------newly added------

}


calcrempay(){
  return this.rempay=this.cashpay+this.cardpay+this.upipay - (Number((this.sumaftrdisc + this.tottaxableamt - this.mandisc + this.ocharges).toFixed(0)));
}
calcrempaycrd(){
  return this.rempay=this.cashpay+this.cardpay+this.upipay -(Number((this.crd).toFixed(0)));
}
updatepayCheckedOptions(p){
  console.log(this.rempay)
if(p=='CASH'){
  this.modeofpay='CASH';
  if(this.cbilly=='yes')
  {
    this.cashpay=(Number((this.crd).toFixed(0)));
    this.cardpay=0;
    this.upipay=0;
  }
  if(this.cbilly=='no')
  {
  this.cashpay=Number((this.sumaftrdisc + this.tottaxableamt - this.mandisc + this.ocharges).toFixed(0));
  this.cardpay=0;
  this.upipay=0;
  }
}
if(p=='CARD'){
  this.modeofpay='CARD';
  if(this.cbilly=='yes')
  {
    this.cashpay=0;
    this.cardpay=(Number((this.crd).toFixed(0)));
    this.upipay=0;
  }
  if(this.cbilly=='no')
  {
  this.cashpay=0;
  this.cardpay=Number((this.sumaftrdisc + this.tottaxableamt - this.mandisc + this.ocharges).toFixed(0));
  this.upipay=0;
  }
}
if(p=='UPI PAYMENT'){
  this.modeofpay='UPI PAYMENT';
  if(this.cbilly=='yes')
  {
    this.cashpay=0;
    this.cardpay=0;
    this.upipay=(Number((this.crd).toFixed(0)));
  }
  if(this.cbilly=='no')
  {
  this.cashpay=0;
  this.cardpay=0;
  this.upipay=Number((this.sumaftrdisc + this.tottaxableamt - this.mandisc + this.ocharges).toFixed(0));
  }
}
if(p=='COMBINE'){
  this.modeofpay='COMBINE';
  this.cashpay=0;
  this.cardpay=0;
  this.upipay=0;
}
if(this.cbilly=='yes')
{
  this.calcrempaycrd();
}
if(this.cbilly=='no')
{
  this.calcrempay();
}
}

//------------------------------------worked fine-------------------------------------------
unbillrestock(){
  this.allroutineidarry=[];
  this.completedroutineidarry=[];
  this.uncompletedroutineidarry=[];

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
      this.allroutineidarry.push({'id':docy.data().routinebillid, 'location':docy.data().location, 'status':docy.data().status});
  })
  //console.log("a:"+this.allroutineidarry);
}).then(()=>{
  this.afs.collection('Bills').doc(this.date2).collection('--Id--').get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      this.completedroutineidarry.push({'id':doc.data().routinebillid,'location':doc.data().location, 'status':doc.data().status});
  })
  //console.log("b:"+this.completedroutineidarry);
}).then(()=>{
  console.log("a:"+JSON.stringify(this.allroutineidarry));
  console.log("b:"+JSON.stringify(this.completedroutineidarry));

  this.uncompletedroutineidarry = this.allroutineidarry.filter((o) => this.completedroutineidarry.indexOf(o) == -1);
console.log("c:"+JSON.stringify(this.uncompletedroutineidarry));

this.uncompletedroutineidarry=this.uncompletedroutineidarry.filter(item => item.status === "using")
console.log("d:"+JSON.stringify(this.uncompletedroutineidarry));

for(let i=0; i<this.uncompletedroutineidarry.length; i++){
  console.log("i"+i);

  this.afs.collection('Bills').doc(this.date2).collection(String(this.uncompletedroutineidarry[i].id)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docz => {

if (this.uncompletedroutineidarry[i].location == 'shop1') {
  this.afs.collection('Products', ref => ref.where('code', '==', docz.data().code)).doc(docz.data().KEYID).update({
    qntyshop1: firebase.firestore.FieldValue.increment(docz.data().qnty)
  })
}
else if (this.uncompletedroutineidarry[i].location == 'shop2') {
  this.afs.collection('Products', ref => ref.where('code', '==', docz.data().code)).doc(docz.data().KEYID).update({
    qntyshop2: firebase.firestore.FieldValue.increment(docz.data().qnty)
  })
}
else if (this.uncompletedroutineidarry[i].location == 'Godown') {
  this.afs.collection('Products', ref => ref.where('code', '==', docz.data().code)).doc(docz.data().KEYID).update({
    qntygodown: firebase.firestore.FieldValue.increment(docz.data().qnty)
  })
}
})
})
.then(()=>{

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.uncompletedroutineidarry[i].id)).set(
    {
      status: "restocked",
    },
    { merge: true }
  )
 
})
console.log("restocked")

}

for(let i=0; i<this.allroutineidarry.length; i++){
  if(this.allroutineidarry[i].status !== "using"){
  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.allroutineidarry[i].id)).delete();
  //-------new--------
  this.afs.collection('Bills').doc(this.date2).collection(String(this.allroutineidarry[i].id)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doca => {
        if(this.allroutineidarry[i].status == "restocked"){
             this.afs.collection('Bills').doc(this.date2).collection(String(this.allroutineidarry[i].id)).doc(doca.data().code).delete();
        }
    })
  })
//-------new--------
  }
  console.log("restocklastline")
  }


  })
})
}
//------------------------------------worked fine-------------------------------------------




validateId(c) {
  return this.b.find(x => x.id === c);
  }

customqntyeditbtnclkview(c){
  this.custqnty=0;
  this.b=[];
  this.b.push({'id':c});
}


customqntyeditbtnclkclose(c,q){
  this.b=[];
  console.log(q);
  this.customqnty(c,q);
}
}
