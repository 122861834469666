import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import * as CanvasJS from './canvasjs.min';
import { Router, ActivatedRoute } from '@angular/router';

import messagebird from 'messagebird';

declare function dashboardSniper(): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit ,AfterViewInit{
  imgcollection: any[]=[];

  blcollection: any;
  sblcollection: any;
  salecollection: any;
  totalbills: any =0;
  shop1bills: any =0;
  shop2bills: any =0;
  godownbills: any =0;

  wholesaletypes: any =0;
  primetypes: any =0;
  institutetypes: any =0;
  regulartypes: any =0;

  gsttypes: any =0;
  igsttypes: any =0;
  taxfreetypes: any =0;

  cashtypes: any =0;
  cardtypes: any =0;
  upitypes: any =0;

  cashamntpaidtypes: any =0;
  cardamntpaidtypes: any =0;
  upiamntpaidtypes: any =0;

  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;
  prateaftqntypre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];
  prateaftqnty: { billid:any; prateaftqntypre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalrate: number;
  summy: { billid:any, shop:string, gst: number, rate: number, taxtype:string, taxableamt: number, amtaftdisc:number, prateaftqnty:number }[]= [];
  summypre: { billid:any, shop:string, gst: number, rate: number, taxtype:string, taxableamt: number, amtaftdisc:number, prateaftqnty:number }[]= [];

  pcollection: any;



  eventdate: string;

  shopbillsarry: { shop1bills: number; shop2bills: number; godownbills: number }[]= [];
  customertypesarry: { wholesaletypes: number; primetypes: number; institutetypes: number; regulartypes: number }[]= [];
  taxtypesarry: { gsttypes: number; igsttypes: number; taxfreetypes: number }[]= [];
  paymentmodetypesarry: {cashtypes: number; cardtypes: number; upitypes:number}[]= [];
  paymentmodeamntpaidtypesarry: {cashamntpaidtypes: number; cardamntpaidtypes: number; upiamntpaidtypes:number}[]= [];

  
  cashtypess1: any =0;
  cardtypess1: any =0;
  upitypess1: any =0;

  cashamntpaidtypess1: any =0;
  cardamntpaidtypess1: any =0;
  upiamntpaidtypess1: any =0;

  paymentmodetypess1arry: {cashtypess1: number; cardtypess1: number; upitypess1:number}[]= [];
  paymentmodeamntpaidtypess1arry: {cashamntpaidtypess1: number; cardamntpaidtypess1: number; upiamntpaidtypess1:number}[]= [];

  
  cashtypess2: any =0;
  cardtypess2: any =0;
  upitypess2: any =0;

  cashamntpaidtypess2: any =0;
  cardamntpaidtypess2: any =0;
  upiamntpaidtypess2: any =0;

  paymentmodetypess2arry: {cashtypess2: number; cardtypess2: number; upitypess2:number}[]= [];
  paymentmodeamntpaidtypess2arry: {cashamntpaidtypess2: number; cardamntpaidtypess2: number; upiamntpaidtypess2:number}[]= [];

  cashtypesGN: any =0;
  cardtypesGN: any =0;
  upitypesGN: any =0;

  cashamntpaidtypesGN: any =0;
  cardamntpaidtypesGN: any =0;
  upiamntpaidtypesGN: any =0;

  paymentmodetypesGNarry: {cashtypesGN: number; cardtypesGN: number; upitypesGN:number}[]= [];
  paymentmodeamntpaidtypesGNarry: {cashamntpaidtypesGN: number; cardamntpaidtypesGN: number; upiamntpaidtypesGN:number}[]= [];

  oscashamnts1: number;
  oscardamnts2: number;
  osupimamntgn: number;


  osmamntwitxs1: number;
  osmamntwitxs2: number;
  osmamntwitxgn: number;
  oprateamntaftrqntys1: number;
  oprateamntaftrqntys2: number;
  oprateamntaftrqntygn: number;
  otxamnts1gst: number;
  otxamnts2gst: number;
  otxamnts1igst: number;
  otxamntgngst: number;
  otxamntgnigst: number;
  otxamnts2igst: number;
  otxamnts1taxfree: number;
  otxamnts2taxfree: number;
  otxamntgntaxfree: number;
  overallsumtaxableamt: number;
  overallsumamtwithtax: number;
  overallpurchaseamntaftrqnty: number;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute, public router: Router) {   }

  addorreplace(array, item){
    const i=array.findIndex(_item => _item.billid===item.billid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplace2(array, item){
   }

   date="";
   day="";
   month="";
   year="";

   clock="";
  clockHandle;
  ngOnInit() {

    this.clockHandle = setInterval(()=>{
      this.clock = moment().format("dddd, MMMM Do YYYY, h:mm:ss a").toLocaleString();
    },1000);

    this.date=moment().date().toString();
    this.day=moment().format('dddd');
    this.month=moment().format('MMMM');
    this.year=moment().format('YYYY');

    //this.imgcollection.push({images:["assets/images/slideimage.png"]});

    this.afs.collection('Settings', ref => ref.where('settingid', "==" , 'dbanner'))
.get().toPromise()
.then(querySnapshot => {
    querySnapshot.forEach(doc => {
     // this.imgcollection.push(doc.data());
        // this.imgcollection.push({images:["assets/images/slideimage.png",doc.data().images]});
        this.imgcollection.push({images:["assets/images/slideimage.png"]});

    });
})
console.log(this.imgcollection);
    this.shopbillsarry=[];

    this.eventdate=moment().format('DD-MM-YYYY')
    this.blcollection =[];
    this.blcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" ,this.eventdate))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    )

  }

  
  // ngForRendred() {
  //   dashboardSniper();
  // }

  ngAfterViewInit() {
    dashboardSniper();


    // var messagebird1 = messagebird('oVolyJ7mCvVYF8nWH9DTDgRk7');

    // var params = {
    //   'to': '+919123552087',
    //   'from': '626aba24-5275-4d1f-b355-018df9369275',
    //   'type': 'text',
    //   'content': {
    //     'text': 'Hello!'
    //   },
    //   reportUrl: null
    // };
    
    // messagebird1.conversations.send(params, function (err, response) {
    //   if (err) {
    //     return console.log(err);
    //   }
    //   console.log(response);
    // });



    //-------newly added-------
    this.blcollection.subscribe(() => {

          this.shop1bills =0;
      this.shop2bills =0;
      this.godownbills =0;

      this.paymentmodetypesarry=[];
      this.cashtypes =0;
      this.cardtypes =0;
      this.upitypes =0;

      this.paymentmodeamntpaidtypesarry=[];
      this.cashamntpaidtypes =0;
      this.cardamntpaidtypes =0;
      this.upiamntpaidtypes =0;

      this.paymentmodetypess1arry=[];
      this.cashtypess1 =0;
      this.cardtypess1 =0;
      this.upitypess1 =0;

      this.paymentmodeamntpaidtypess1arry=[];
      this.cashamntpaidtypess1 =0;
      this.cardamntpaidtypess1 =0;
      this.upiamntpaidtypess1 =0;
      
      this.paymentmodetypess2arry=[];
      this.cashtypess2 =0;
      this.cardtypess2 =0;
      this.upitypess2 =0;

      this.paymentmodeamntpaidtypess2arry=[];
      this.cashamntpaidtypess2 =0;
      this.cardamntpaidtypess2 =0;
      this.upiamntpaidtypess2 =0;

      this.paymentmodetypesGNarry=[];
      this.cashtypesGN =0;
      this.cardtypesGN =0;
      this.upitypesGN =0;

      this.paymentmodeamntpaidtypesGNarry=[];
      this.cashamntpaidtypesGN =0;
      this.cardamntpaidtypesGN =0;
      this.upiamntpaidtypesGN =0;

      this.oscashamnts1 = 0;
      this.oscardamnts2 = 0;
      this.osupimamntgn = 0;
      
    this.sblcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" ,this.eventdate)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      for(let i=0;i<doc.data().billsid.length;i++){
 
      if (String(doc.data().billsid[i]).slice(0, 2) == "S1") {
        this.shop1bills = this.shop1bills+1;
        this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
      }
      else if (String(doc.data().billsid[i]).slice(0, 2) == "S2") {
        this.shop2bills = this.shop2bills+1;
        this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})      
      }
      else if (String(doc.data().billsid[i]).slice(0, 2) == "GN") {
        this.godownbills = this.godownbills+1;
        this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
      }
    }
  })

    })
    //-------newly added-------

  this.tottaxableamtpre=0;
  this.totamtwithtaxpre=0; 
  this.sumaftrdiscpre=0; 
  this.prateaftqntypre=0;

  this.totalamountpre=0;

  this.tottaxableamt=[];
  this.totamtwithtax=[]; 
  this.sumaftrdisc=[]; 
this.totalamount=[];
  
  this.summy=[];


      this.afs.collection('Bills', ref => ref.where('date2', "==" ,this.eventdate)).get().toPromise().then(querySnapshot => {
        querySnapshot.forEach(doc => {

          for(let i=1;i<=doc.data().billsid.length;i++){
            
            
            this.tottaxableamtpre=0;
            this.totamtwithtaxpre=0; 
            this.sumaftrdiscpre=0; 
            this.prateaftqntypre=0;

            this.totalamountpre=0;
        
         this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(doc.data().billsid[i-1].substring(4)))).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docy => {
                          this.pcollection = this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().routinebillid)).snapshotChanges()
                          .pipe( 
                            map(actions => actions.map(a => a.payload.doc.data()))
           );
        
          this.pcollection.subscribe((posts) => {

            if (String(doc.data().billsid[i-1]).slice(0, 2) == "S1") {            
              if(docy.data().paymentmode == 'CASH'){
                this.cashtypess1 = this.cashtypess1+1;
                this.addorreplace(this.paymentmodetypess1arry,{'cashtypess1':this.cashtypess1 , 'cardtypess1':this.cardtypess1, 'upitypess1':this.upitypess1})
               
                this.cashamntpaidtypess1 = this.cashamntpaidtypess1+docy.data().amountpaid;
                this.addorreplace(this.paymentmodeamntpaidtypess1arry,{'cashamntpaidtypess1':this.cashamntpaidtypess1 , 'cardamntpaidtypess1':this.cardamntpaidtypess1, 'upiamntpaidtypess1':this.upiamntpaidtypess1})
              }
              if(docy.data().paymentmode == 'CARD'){
                this.cardtypess1 = this.cardtypess1+1;
                this.addorreplace(this.paymentmodetypess1arry,{'cashtypess1':this.cashtypess1 , 'cardtypess1':this.cardtypess1, 'upitypess1':this.upitypess1})
               
                this.cardamntpaidtypess1 = this.cardamntpaidtypess1+docy.data().amountpaid;
                this.addorreplace(this.paymentmodeamntpaidtypess1arry,{'cashamntpaidtypess1':this.cashamntpaidtypess1 , 'cardamntpaidtypess1':this.cardamntpaidtypess1, 'upiamntpaidtypess1':this.upiamntpaidtypess1})
              }
              if(docy.data().paymentmode == 'UPI PAYMENT'){
                this.upitypess1 = this.upitypess1+1;
                this.addorreplace(this.paymentmodetypess1arry,{'cashtypess1':this.cashtypess1 , 'cardtypess1':this.cardtypess1, 'upitypess1':this.upitypess1})
               
                this.upiamntpaidtypess1 = this.upiamntpaidtypess1+docy.data().amountpaid;
                this.addorreplace(this.paymentmodeamntpaidtypess1arry,{'cashamntpaidtypess1':this.cashamntpaidtypess1 , 'cardamntpaidtypess1':this.cardamntpaidtypess1, 'upiamntpaidtypess1':this.upiamntpaidtypess1})
               }
              }
              else if (String(doc.data().billsid[i-1]).slice(0, 2) == "S2") {
                this.shop2bills = this.shop2bills+1;
                this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
              
                if(docy.data().paymentmode == 'CASH'){
                  this.cashtypess2 = this.cashtypess2+1;
                  this.addorreplace(this.paymentmodetypess2arry,{'cashtypess2':this.cashtypess2 , 'cardtypess2':this.cardtypess2, 'upitypess2':this.upitypess2})
                  
                  this.cashamntpaidtypess2 = this.cashamntpaidtypess2+docy.data().amountpaid;
                  this.addorreplace(this.paymentmodeamntpaidtypess2arry,{'cashamntpaidtypess2':this.cashamntpaidtypess2 , 'cardamntpaidtypess2':this.cardamntpaidtypess2, 'upiamntpaidtypess2':this.upiamntpaidtypess2})
                }
                if(docy.data().paymentmode == 'CARD'){
                  this.cardtypess2 = this.cardtypess2+1;
                  this.addorreplace(this.paymentmodetypess2arry,{'cashtypess2':this.cashtypess2 , 'cardtypess2':this.cardtypess2, 'upitypess2':this.upitypess2})
                  
                  this.cardamntpaidtypess2 = this.cardamntpaidtypess2+docy.data().amountpaid;
                  this.addorreplace(this.paymentmodeamntpaidtypess2arry,{'cashamntpaidtypess2':this.cashamntpaidtypess2 , 'cardamntpaidtypess2':this.cardamntpaidtypess2, 'upiamntpaidtypess2':this.upiamntpaidtypess2})
                  }
                if(docy.data().paymentmode == 'UPI PAYMENT'){
                  this.upitypess2 = this.upitypess2+1;
                  this.addorreplace(this.paymentmodetypess2arry,{'cashtypess2':this.cashtypess2 , 'cardtypess2':this.cardtypess2, 'upitypess2':this.upitypess2})
                 
                  this.upiamntpaidtypess2 = this.upiamntpaidtypess2+docy.data().amountpaid;
                  this.addorreplace(this.paymentmodeamntpaidtypess2arry,{'cashamntpaidtypess2':this.cashamntpaidtypess2 , 'cardamntpaidtypess2':this.cardamntpaidtypess2, 'upiamntpaidtypess2':this.upiamntpaidtypess2})
                 }
                 }
                 else if (String(doc.data().billsid[i-1]).slice(0, 2) == "GN") {
                  this.godownbills = this.godownbills+1;
                  this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
                
                  if(docy.data().paymentmode == 'CASH'){
                    this.cashtypesGN = this.cashtypesGN+1;
                    this.addorreplace(this.paymentmodetypesGNarry,{'cashtypesGN':this.cashtypesGN , 'cardtypesGN':this.cardtypesGN, 'upitypesGN':this.upitypesGN})
                  
                    this.cashamntpaidtypesGN = this.cashamntpaidtypesGN+docy.data().amountpaid;
                    this.addorreplace(this.paymentmodeamntpaidtypesGNarry,{'cashamntpaidtypesGN':this.cashamntpaidtypesGN , 'cardamntpaidtypesGN':this.cardamntpaidtypesGN, 'upiamntpaidtypesGN':this.upiamntpaidtypesGN})
                  }
                  if(docy.data().paymentmode == 'CARD'){
                    this.cardtypesGN = this.cardtypesGN+1;
                    this.addorreplace(this.paymentmodetypesGNarry,{'cashtypesGN':this.cashtypesGN , 'cardtypesGN':this.cardtypesGN, 'upitypesGN':this.upitypesGN})
                  
                    this.cardamntpaidtypesGN = this.cardamntpaidtypesGN+docy.data().amountpaid;
                    this.addorreplace(this.paymentmodeamntpaidtypesGNarry,{'cashamntpaidtypesGN':this.cashamntpaidtypesGN , 'cardamntpaidtypesGN':this.cardamntpaidtypesGN, 'upiamntpaidtypesGN':this.upiamntpaidtypesGN})
                  }
                  if(docy.data().paymentmode == 'UPI PAYMENT'){
                    this.upitypesGN = this.upitypesGN+1;
                    this.addorreplace(this.paymentmodetypesGNarry,{'cashtypesGN':this.cashtypesGN , 'cardtypesGN':this.cardtypesGN, 'upitypesGN':this.upitypesGN})
                  
                    this.upiamntpaidtypesGN = this.upiamntpaidtypesGN+docy.data().amountpaid;
                    this.addorreplace(this.paymentmodeamntpaidtypesGNarry,{'cashamntpaidtypesGN':this.cashamntpaidtypesGN , 'cardamntpaidtypesGN':this.cardamntpaidtypesGN, 'upiamntpaidtypesGN':this.upiamntpaidtypesGN})
                  }
                }
            console.log("posty:"+posts); //This is the array of posts, not an observable
        
            if(doc.data().billsid[i-1].substring(3,4)=='G'){
            this.summypre=(_(posts).groupBy('cgst')
              .map((objs, key) => {
                  return {
                'billid':doc.data().billsid[i-1].substring(4),
                'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'taxtype':'gst',
                'taxableamt':2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                'prateaftqnty': (_.sumBy(objs, ('purchaserateafterqnty'))),  
                  }
              })
              .value()
            );
            }
            if(doc.data().billsid[i-1].substring(3,4)=='I'){
              this.summypre=(_(posts).groupBy('igst')
                .map((objs, key) => {
                    return {
                  'billid':doc.data().billsid[i-1].substring(4),
                  'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                  'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                  'taxtype':'igst',
                  'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                  'prateaftqnty': (_.sumBy(objs, ('purchaserateafterqnty'))),
                }
                })
                .value()
              );
              }
              if(doc.data().billsid[i-1].substring(3,4)=='T'){
                this.summypre=(_(posts).groupBy('taxfree')
                  .map((objs, key) => {
                      return {
                    'billid':doc.data().billsid[i-1].substring(4),
                    'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                    'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                    'taxtype':'taxfree',
                    'taxableamt':(parseFloat('0.00')),
                    'prateaftqnty': (_.sumBy(objs, ('purchaserateafterqnty'))),
                  }
                  })
                  .value()
                );
                }
            this.summy=this.summy.concat(this.summypre);
        
            this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
            this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
            this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
            this.prateaftqntypre=this.summypre.reduce((sum2, item2) => sum2 + item2.prateaftqnty, 0);

            this.addorreplace(this.tottaxableamt,{'billid':doc.data().billsid[i-1].substring(4), tottaxableamtpre:this.tottaxableamtpre})
            this.addorreplace(this.totamtwithtax,{'billid':doc.data().billsid[i-1].substring(4), totamtwithtaxpre:this.totamtwithtaxpre})
            this.addorreplace(this.sumaftrdisc,{'billid':doc.data().billsid[i-1].substring(4), sumaftrdiscpre:this.sumaftrdiscpre})
            this.addorreplace(this.prateaftqnty,{'billid':doc.data().billsid[i-1].substring(4), prateaftqntypre:this.prateaftqntypre})

            console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
        
              console.log("SUMMYYY:"+JSON.stringify(this.summy));
        

              

        const result = this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
        const result2 = this.summy.reduce((sum2, item2) => sum2 + item2.taxableamt, 0);
        const result3 = this.summy.reduce((sum2, item2) => sum2 + item2.prateaftqnty, 0);
        const result4 = (result+result2) - result3;
        //----------chart-------------

        CanvasJS.addColorSet("blueShades",
        [//colorSet Array

        "#00308F",
        "#00FFFF",               
        ]);

       let chart = new CanvasJS.Chart("chartContainer", {
        //theme: "light2",
        colorSet: "blueShades",
        animationEnabled: true,
        bevelEnabled: true,
        // ex7ortEnabled: true,
        // title:{
        //   text: "Today Sales",
        //   fontFamily: "tahoma",
        //   fontSize:20
        // },
        toolTip: {
			cornerRadius: 50
		},
        data: [{
          type: "doughnut",
		startAngle: 60,
		//innerRadius: 60,
    radius: "100%",
		//indexLabelFontSize: 17,
          //type: "pie",
          showInLegend: true,
          toolTipContent: "<b>{name}</b>: ₹{y} (#percent%)",
          //indexLabel: "{name} '\n' #percent%",
          dataPoints: [
            { y: result, name: "Net" },
            { y: result2, name: "Tax" },
          ]
        }]
      });        
      chart.render();
      
       //----------chart-------------

        //----------chart2-------------

        CanvasJS.addColorSet("greenShades",
        [//colorSet Array

        "#1e331e",
        "#89e689",               
        ]);

       let chart2 = new CanvasJS.Chart("chartContainer2", {
        //theme: "light2",
        colorSet: "greenShades",
        animationEnabled: true,
        bevelEnabled: true,
        // exportEnabled: true,
        // title:{
        //   text: "Today Sales",
        //   fontFamily: "tahoma",
        //   fontSize:20
        // },
        toolTip: {
			cornerRadius: 50
		},
        data: [{
          type: "doughnut",
		startAngle: 10,
		//innerRadius: 60,
    radius: "100%",
		//indexLabelFontSize: 17,
          //type: "pie",
          showInLegend: true,
          toolTipContent: "<b>{name}</b>: ₹{y} (#percent%)",
          //indexLabel: "{name} '\n' #percent%",
          dataPoints: [
            { y: result3, name: "Purchase" },
            { y: result4, name: "Profit" },
          ]
        }]
      });        
      chart2.render();
      
       //----------chart2-------------
      console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+result);
      
      this.overallsumtaxableamt = this.summy.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
      this.overallsumamtwithtax = this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.overallsumtaxableamt;

      this.overallpurchaseamntaftrqnty = this.summy.reduce((sum1, item1) => sum1 + item1.prateaftqnty, 0)

                  this.totalbills = doc.data().billsid.length;

      this.otxamnts1gst=this.summy.filter(item => item.shop === "S1" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2gst=this.summy.filter(item => item.shop === "S2" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgngst=this.summy.filter(item => item.shop === "GN" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts1igst=this.summy.filter(item => item.shop === "S1" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2igst=this.summy.filter(item => item.shop === "S2" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgnigst=this.summy.filter(item => item.shop === "GN" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts1taxfree=this.summy.filter(item => item.shop === "S1" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2taxfree=this.summy.filter(item => item.shop === "S2" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgntaxfree=this.summy.filter(item => item.shop === "GN" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);

      this.osmamntwitxs1=this.summy.filter(item => item.shop === "S1").reduce((a, b) => a + b.amtaftdisc, 0);
       this.osmamntwitxs2=this.summy.filter(item => item.shop === "S2").reduce((a, b) => a + b.amtaftdisc, 0);
       this.osmamntwitxgn=this.summy.filter(item => item.shop === "GN").reduce((a, b) => a + b.amtaftdisc, 0);

       this.oprateamntaftrqntys1=this.summy.filter(item => item.shop === "S1").reduce((a, b) => a + b.prateaftqnty, 0);
       this.oprateamntaftrqntys2=this.summy.filter(item => item.shop === "S2").reduce((a, b) => a + b.prateaftqnty, 0);
       this.oprateamntaftrqntygn=this.summy.filter(item => item.shop === "GN").reduce((a, b) => a + b.prateaftqnty, 0);

    })

  })
})

}

    })
  })
})//-------newly added-------
  }


  viewreport() {  
      this.router.navigate(['../billentry'], {relativeTo: this.route})
        }


}
