import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import { forEach,sum} from 'lodash';
import * as _ from 'lodash';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css'],
})

export class ProductdetailsComponent implements AfterViewInit, OnInit {

  displayedColumns = ['code','brand','category','color','size','modelno','labelcode','hsncode','gst','prate','rate','qntygodown','qntyshop1','qntyshop2','totqnty','estrate','estrate2','regulardisc','primedisc','institutedisc','wholesaledisc'];
  dataSource: MatTableDataSource<any>; 
  
  displayedColumns2 = ['brand','category','code','color','size','modelno','labelcode','hsncode','gst','prate','rate','qntygodown','qntyshop1','qntyshop2','totqnty','estrate','estrate2','regulardiscP','regulardisc','primediscP','primedisc','institutediscP','institutedisc','wholesalediscP','wholesaledisc'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  totalValue: any;
  
  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder) {   }
  prcollection: any;
  qntycollection: any;

  search: any;


  ngAfterViewInit() {
    this.prcollection = this.afs.collection('Products').valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    
      
       //It searches Category columns. To search in entire table skip it
       this.dataSource.filterPredicate = (data, filter) =>
       (data.category.indexOf(filter) !== -1);
       //----------------------------------------------------------------------
       console.log((this.dataSource));
     })
  }

  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toUpperCase(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;  
  };

  ngOnInit() {
  }

  getTotalQntyGodown(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntygodown = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntygodown);
  });
  return tqntygodown;
  }
  }

  getTotalQntyShop1(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntyshop1 = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntyshop1);
  });
  return tqntyshop1;
  }
  }

  getTotalQntyShop2(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntyshop2 = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntyshop2);
  });
  return tqntyshop2;
  }
  }



}
