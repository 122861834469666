import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Drawer2Service } from "../../assets/services/drawer2.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig, MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';


import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-billdelete',
  templateUrl: './billdelete.component.html',
  styleUrls: ['./billdelete.component.css'],
  encapsulation: ViewEncapsulation.None,   //snackbar panelcss
})
export class BilldeleteComponent implements AfterViewInit {

  message:boolean;
  selectedDate: string;
  prcollection: any;
  prdcollection: any;

favoriteCustomer: string;
customers: string[] = ['wholesale', 'prime', 'institute', 'regular'];

selectedOptions: string[]=[];


myary: { billid: any, checked: boolean }[]= [] //declare before constructor

arybilldetails:{
  billid:any;
  code:string;
  productname: string;
  hsncode: string;
  discount: number;
  qnty : number;
  unit2 : string;
  rate: number;
  cgst: number;
  sgst: number;
  igst:number;
}[]=[];

aryaddressdetails:{
  billid:any;
  billono:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerType: string;

}[]=[];

  eventdate: string;

  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;
  discountspre: number;
  amountpaidspre: number;
  otherchgpre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];
  discounts: { billid:any; discountspre: number }[]= [];
  otherchg: { billid:any; otherchgpre: number }[]= [];
  amountpaids: { billid:any; amountpaidspre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalrate: number;
  summy: { billid:any; gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];
  summypre: { billid:any; gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];

  pcollection: any;
  bbcid: any;

  pressedtogglebtn: any;

  billbarcode: string; 
  crcollection: any;
  bcollection: any;

constructor(private afs: AngularFirestore, public fb: FormBuilder, public snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet, private changeDetection: ChangeDetectorRef) {   }

addorreplace(array, item){
 const i=array.findIndex(_item => _item.billid===item.billid);
 if(i>-1) array[i] =item;
 else array.push(item);
}

ngAfterViewInit() {
  
this.favoriteCustomer=this.customers[3]; // set default radio button checked
this.selectedDate=moment().format('DD-MM-YYYY')
this.eventdate=moment().format('DD-MM-YYYY')


// this.prcollection =[];
// //this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-12-2019')).snapshotChanges()
// this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-02-2020')).snapshotChanges()

//   .pipe(
//     map(actions => actions.map(a => a.payload.doc.data()))
//   );
  this.prdcollection =[];
  this.prdcollection = this.afs.collection('Bills').doc(this.selectedDate).collection('1').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  this.myary=[];

  
  
  this.bcollection =   this.afs.collection('Bills').doc(this.eventdate).collection('--Id--').snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.bcollection.subscribe((posts) => {
    this.afs.collection('Bills', ref => ref.where('date2', "==" , this.selectedDate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          //this.myary.push({'id':doc.data().billsid[0]});
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':doc.data().billsid[i], 'checked':false});
        }
          console.log("GGGGG: "+JSON.stringify(this.myary));

      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

})


}


onSelect(event){
  console.log(event);
  this.selectedDate = event;

  this.eventdate=moment(event).format('DD-MM-YYYY');

    //this.snackBar.open("MESSAGE", "ACTION" ? 'OK' : undefined, {duration: 2000,verticalPosition:'bottom',horizontalPosition:'end',panelClass :['sbowncss']});
    //this.snackBar.open(moment(event).format('DD-MM-YYYY'), "ACTION" ? 'OK' : undefined, {duration: 2000,verticalPosition:'bottom',horizontalPosition:'end',panelClass :['sbowncss']});

    //this.selectedDate=(moment(event).format('DD-MM-YYYY'));
    //console.log(String(this.selectedDate));

    this.prdcollection =[];
  this.prdcollection = this.afs.collection('Bills').doc(moment(event).format('DD-MM-YYYY')).collection('1').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  this.tottaxableamtpre=0;
  this.totamtwithtaxpre=0; 
  this.sumaftrdiscpre=0; 
  this.discountspre=0;
  this.amountpaidspre=0;
  this.otherchgpre=0;

  this.totalamountpre=0;

  this.tottaxableamt=[];
  this.totamtwithtax=[]; 
  this.sumaftrdisc=[]; 
  this.discounts=[];
  this.amountpaids=[];
  this.otherchg=[];

this.totalamount=[];
  
  this.summy=[];
  this.arybilldetails=[];
  this.aryaddressdetails=[];
  this.myary=[];
  this.prcollection =[];
  this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , moment(event).format('DD-MM-YYYY')))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          //this.myary.push({'id':doc.data().billsid[0]});
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.myary.push({'billid':doc.data().billsid[i], 'checked':false});
        }
          console.log("GGGGG: "+JSON.stringify(this.myary));

      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

}




  updateCheckedOptions(tta,i){
console.log("tta: "+JSON.stringify(tta));
    tta.checked = !tta.checked;

    this.tottaxableamtpre=0;
    this.totamtwithtaxpre=0; 
    this.sumaftrdiscpre=0; 
    this.discountspre=0;
    this.amountpaidspre=0; 
    this.otherchgpre=0;

    this.totalamountpre=0;

    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {

          this.pcollection =   this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().routinebillid)).snapshotChanges()      
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  this.pcollection.subscribe((posts) => {
    if(tta.checked==true){

      this.addorreplace(this.discounts,{'billid':docy.data().currbillfullid, discountspre:docy.data().mandiscount});
      this.addorreplace(this.amountpaids,{'billid':docy.data().currbillfullid, amountpaidspre:docy.data().amountpaid});
      this.addorreplace(this.otherchg,{'billid':docy.data().currbillfullid, otherchgpre:docy.data().othercharges});

    console.log("posty:"+posts); //This is the array of posts, not an observable

      if(tta.billid.substring(3,4)=='G'){
    this.summypre=(_(posts).groupBy('cgst')
      .map((objs, key) => {
          return {
        'billid':tta.billid,
        'cgst': key,             
        'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
        'gstp':(key*2),
        'cgstp':(parseFloat(key)),
        'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'sgstp':(parseFloat(key)),
        'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'igstp':(parseFloat('0')),
        'igsta':(parseFloat('0.00')),
        'taxableamt': 2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
          }
      })
      .value()
    );
    this.summy=this.summy.concat(this.summypre);

    this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
    this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
    this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

    this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
    this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
    this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

   // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
   // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
   // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

    console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));

      console.log("SUMMYYY:"+JSON.stringify(this.summy));
    }

      if(tta.billid.substring(3,4)=='I'){
        this.summypre=(_(posts).groupBy('igst')
        .map((objs, key) => {
          return {
              'billid':tta.billid,
              'igst': key,
              'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
              'gstp':(0),
              'cgstp':(parseFloat('0')),
              'cgsta':(parseFloat('0.00')),
              'sgstp':(parseFloat('0')),
              'sgsta':(parseFloat('0.00')),
              'igstp':(parseFloat(key)),
              'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
              'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
               }
      })
      .value()
        );
        this.summy=this.summy.concat(this.summypre);
    
        this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
        this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
        this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

        this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
        this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
        this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})
        
        // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
        // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
        // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

        console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
    
          console.log("SUMMYYY:"+JSON.stringify(this.summy));
        }

        if(tta.billid.substring(3,4)=='T'){
          this.summypre=(_(posts).groupBy('taxfree')
          .map((objs, key) => {
            return {
                'billid':tta.billid,
                'taxfree': key,
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'gstp':(0),
                'cgstp':(parseFloat('0')),
                'cgsta':(parseFloat('0.00')),
                'sgstp':(parseFloat('0')),
                'sgsta':(parseFloat('0.00')),
                'igstp':(parseFloat('0')),
                'igsta':(parseFloat('0.00')),
                'taxableamt':(parseFloat('0.00')),
                 }
        })
        .value()
          );
          this.summy=this.summy.concat(this.summypre);
      
          this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
          this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
          this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

          this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
          this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
          this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

          // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
          // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
          // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

          console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
          console.log("SUMMYYY:"+JSON.stringify(this.summy));

          }

    console.log("posts:"+posts); //This is the array of posts, not an observable

    let ratetotal = 0;
    let amounttotal = 0;
  console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts.length);
  for (var i = 0; i < posts.length; i++) {
      if (posts[i].rate) {
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].rate);
          ratetotal += posts[i].rate;
          this.totalrate = ratetotal;

          amounttotal += (posts[i].rate * posts[i].qnty);
          this.totalamountpre = amounttotal;
      }
  }

  this.addorreplace(this.totalamount,{'billid':tta.billid, totalamountpre:this.totalamountpre})

 // this.totalamount.push({'billid':tta.billid, totalamountpre:this.totalamountpre})

  console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+ratetotal);
  return [ratetotal,amounttotal];
  }


  });

    });
  });


  this.afs.collection('Bills').doc(this.eventdate).collection('--stats--').doc(tta.billid.substring(4)).get().toPromise().then(doc => {
        if(tta.checked==true){
         console.log("NNNNNN"+doc.data().customerName);

         this.addorreplace(this.aryaddressdetails,{'billid':tta.billid,
         billono: doc.data().billrefno,
            customerName: doc.data().customerName,
            customerAddress11: doc.data().customerAddress11,
            customerAddress12: doc.data().customerAddress12,
            customerAddress21: doc.data().customerAddress21,
            customerAddress22: doc.data().customerAddress22,
            customerContact: doc.data().customerContact,
            customerGst: doc.data().customerGst,
            customerType: doc.data().customerType,
          });

          // this.aryaddressdetails.push({'billid':tta.billid,
          //   customerName: doc.data().customerName,
          //   customerAddress11: doc.data().customerAddress11,
          //   customerAddress12: doc.data().customerAddress12,
          //   customerAddress21: doc.data().customerAddress21,
          //   customerAddress22: doc.data().customerAddress22,
          //   customerContact: doc.data().customerContact,
          //   customerGst: doc.data().customerGst,
          //   customerType: doc.data().customerType,
          // });

            console.log("YYYYY"+JSON.stringify(this.aryaddressdetails));
        }
        else {
          console.log("GGG: "+JSON.stringify(this.aryaddressdetails));
          const addrdetailsary =  this.aryaddressdetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
          if (addrdetailsary > -1) {
            this.aryaddressdetails.splice(addrdetailsary, 1);
          }
          console.log("GGGGG: "+JSON.stringify(this.aryaddressdetails));
        }
        
      return false;         

  });




    console.log(tta.checked);
    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {

        this.addorreplace(this.discounts,{'billid':docy.data().currbillfullid, discountspre:docy.data().mandiscount});
        this.addorreplace(this.amountpaids,{'billid':docy.data().currbillfullid, amountpaidspre:docy.data().amountpaid});
        this.addorreplace(this.otherchg,{'billid':docy.data().currbillfullid, otherchgpre:docy.data().othercharges});

      this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          if(tta.checked==true){
           // this.myary.push({'billid':tta.billid});
            
            this.addorreplace(this.arybilldetails,{'billid':tta.billid,
            'code':doc.data().code,
            'productname': doc.data().productname,
            'hsncode' : doc.data().hsncode,
            'discount' : doc.data().discount,
            'qnty' : doc.data().qnty,
            'unit2' : doc.data().unit2,
            'rate': doc.data().rate,
            'cgst': doc.data().cgst,
            'sgst': doc.data().sgst,
            'igst': doc.data().igst,
          });
    
          // this.arybilldetails.push({'billid':tta.billid,
          //     'code':doc.data().code,
          //     'productname': doc.data().productname,
          //     'hsncode' : doc.data().hsncode,
          //     'discount' : doc.data().discount,
          //     'qnty' : doc.data().qnty,
          //     'unit2' : doc.data().unit2,
          //     'rate': doc.data().rate,
          //     'cgst': doc.data().cgst,
          //     'sgst': doc.data().sgst,
          //     'igst': doc.data().igst,
          //   });

              console.log("YYYYY"+JSON.stringify(this.arybilldetails));
          }
          else {
            console.log("GGG: "+JSON.stringify(this.arybilldetails));
            const detailsary =  this.arybilldetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (detailsary > -1) {
              this.arybilldetails.splice(detailsary, 1);
            }
            console.log("GGGGG: "+JSON.stringify(this.arybilldetails));

            console.log("SSSS: "+JSON.stringify(this.summy));
            const gstdetailsary =  this.summy.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (gstdetailsary > -1) {
              this.summy.splice(gstdetailsary, 1);
            }
            console.log("SSSS: "+JSON.stringify(this.summy));

            console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
            const tottaxableamtdetailsary =  this.tottaxableamt.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (tottaxableamtdetailsary > -1) {
              this.tottaxableamt.splice(tottaxableamtdetailsary, 1);
            }
            console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));

            console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));
            const totamtwithtaxdetailsary =  this.totamtwithtax.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (totamtwithtaxdetailsary > -1) {
              this.totamtwithtax.splice(totamtwithtaxdetailsary, 1);
            }
            console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));

            console.log("TTTTTTAAAA: "+JSON.stringify(this.sumaftrdisc));
            const sumaftrdiscdetailsary =  this.sumaftrdisc.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (sumaftrdiscdetailsary > -1) {
              this.sumaftrdisc.splice(sumaftrdiscdetailsary, 1);
            }
            console.log("TTTTTTAAAA: "+JSON.stringify(this.sumaftrdisc));

            console.log("TTTTTTAAAA: "+JSON.stringify(this.totalamount));
            const totalamountdetailsary =  this.totalamount.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (totalamountdetailsary > -1) {
              this.totalamount.splice(totalamountdetailsary, 1);
            }
            console.log("TTTTTTAAAA: "+JSON.stringify(this.totalamount));

          }
          
        }) ;
        return false;         
      }
  
    });
  });
  
    });
  
   console.log("R: "+JSON.stringify(this.myary));
   return this.myary;
  
  }

  selectall(){    
    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.eventdate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':String(doc.data().billsid[i]), 'checked':true})
            this.selectedOptions.push(String(doc.data().billsid[i]))
            this.updateCheckedOptions({"billid":String(doc.data().billsid[i]), "checked":false},0);

          }
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

   }

  deselectall(){
    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.eventdate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':String(doc.data().billsid[i]), 'checked':false})
            //---------------remove an array item-----------------
            const index = this.selectedOptions.indexOf(String(doc.data().billsid[i]));
            if (index > -1 ) {
            this.selectedOptions.splice(index,1)
            }
            //---------------remove an array item-----------------
            this.updateCheckedOptions({"billid":String(doc.data().billsid[i]), "checked":true},0);

          }
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });


  }


  pressedtogglebtnreset(){
    this.pressedtogglebtn=null;
  }

  sortagain(){
    //var sortedmyaryagain = this.myary.map(el => el.billid.substring(4))
    this.myary.sort(function(a,b){return a.billid.substring(4) - b.billid.substring(4)})
  }

  srchbrcode(){

    var first =this.billbarcode;
    if(this.myary.some(e => e.billid === first))//checks bill exist or not
    {
    this.myary.sort(function(x,y){ return x.billid == first ? -1 : y.billid == first ? 1 : 0})

    this.addorreplace(this.myary,{'billid':this.billbarcode, 'checked':true})
    this.addorreplace(this.selectedOptions,this.billbarcode)
    this.updateCheckedOptions({"billid":this.billbarcode, "checked":false},0);
    this.changeDetection.detectChanges();
    }
    else{
      console.log("Bill Not Found");
    }
  }
  
  openBottomSheet(billid): void {
    this._bottomSheet.open(BottomSheetBillDelete,{data: { params: {'id':billid,'date':this.eventdate} }}).afterDismissed().subscribe(x=> {
      if(!x){
      this.ngAfterViewInit();
      }
    })
  }

}

@Component({
  selector: 'bottomsheet-billdelete',
  templateUrl: 'bottomsheet-billdelete.html',
})
export class BottomSheetBillDelete {
  totalbills: number =0;
  routinebillid: number =0;
  billref: string='';
  
  //constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetBillDelete>) {}
  constructor(private afs: AngularFirestore, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private _bottomSheetRef: MatBottomSheetRef<BottomSheetBillDelete> ) {}
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
  
  hello(event){
    event.preventDefault();
    console.log(this.data.params['date']);
    console.log(this.data.params['id']);

    this.afs.collection('Bills').doc(this.data.params['date']).collection('--stats--').doc(this.data.params['id'].substring(4)).get().toPromise().then(docz => {

      this.billref=docz.data().billrefno
      console.log(this.billref);
    })
    .then(()=>{
    this.afs.collection('Bills').doc(this.data.params['date']).collection('--Id--',ref => ref.where('currentbillid', '==', Number(this.data.params['id'].substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {

      this.routinebillid=docy.data().routinebillid
      })
    }).then(()=>{

    this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.params['date']))
    .get().toPromise()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
            this.totalbills=doc.data().totalbills;
        })
        console.log(this.totalbills);
      })
      .then(()=>{
        //Dont delete 
        // this.afs.collection('Bills').doc(this.data.params['date']).collection('--Id--').doc(this.data.params['id'].substring(4)).delete().then(()=>{
        //   console.log("--Id-- deleted successfully")
        // })
        // this.afs.collection('Bills').doc(this.data.params['date']).collection(String(this.routinebillid)).doc().delete().then(()=>{
        //   console.log("routinebillid deleted successfully")
        // })
        this.afs.collection('Bills').doc(this.data.params['date']).collection('--Id--').doc(this.data.params['id'].substring(4)).delete().then(()=>{
          console.log("--Id-- deleted successfully")
          this.afs.collection('Bills').doc(this.data.params['date']).collection('--stats--').doc(this.data.params['id'].substring(4)).delete().then(()=>{
            console.log("--stats-- deleted successfully")
            this.afs.collection('Bills').doc(this.data.params['date']).collection(String(this.routinebillid)).get().toPromise().then(querySnapshot => {
              querySnapshot.forEach(docm => {
                this.afs.collection('Bills').doc(this.data.params['date']).collection(String(this.routinebillid)).doc(docm.data().code).delete()
              })
                 console.log("routinebillid deleted successfully")
               })
          })
        })
        this.afs.collection('Bills').doc(this.data.params['date']).update({
              totalbills:this.totalbills - 1,
              billsid:firebase.firestore.FieldValue.arrayRemove(this.data.params['id']),
              billsrefno:firebase.firestore.FieldValue.arrayRemove(this.billref)
        }).then(()=>{
          console.log("billids array deleted / total bills updated successfully")
          this._bottomSheetRef.dismiss();
        })
      })
    })
    })
  }

  closebtmsheet(event){
    event.preventDefault();
    this._bottomSheetRef.dismiss();
  }

}
