import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { Drawer10Service } from "../../assets/services/drawer10.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';

declare function snipMe(): any;


@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent implements AfterViewInit {
  //----------------default 'today' selected in range list--------------
  @ViewChild(DaterangepickerComponent, {static: true}) private datePicker: DaterangepickerComponent;
  //----------------default 'today' selected in range list--------------

  
  value=0; //progressbar



  //-------------------------
  shop1bills: any =0;
  shop2bills: any =0;
  godownbills: any =0;

  wholesaletypes: any =0;
  primetypes: any =0;
  institutetypes: any =0;
  regulartypes: any =0;

  gsttypes: any =0;
  igsttypes: any =0;
  taxfreetypes: any =0;

  cashtypes: any =0;
  cardtypes: any =0;
  upitypes: any =0;

  cashamntpaidtypes: any =0;
  cardamntpaidtypes: any =0;
  upiamntpaidtypes: any =0;

  shopbillsarry: { shop1bills: number; shop2bills: number; godownbills: number }[]= [];
  customertypesarry: { wholesaletypes: number; primetypes: number; institutetypes: number; regulartypes: number }[]= [];
  taxtypesarry: { gsttypes: number; igsttypes: number; taxfreetypes: number }[]= [];
  paymentmodetypesarry: {cashtypes: number; cardtypes: number; upitypes:number}[]= [];
  paymentmodeamntpaidtypesarry: {cashamntpaidtypes: number; cardamntpaidtypes: number; upiamntpaidtypes:number}[]= [];

  wholesaletypess1: any =0;
  primetypess1: any =0;
  institutetypess1: any =0;
  regulartypess1: any =0;

  gsttypess1: any =0;
  igsttypess1: any =0;
  taxfreetypess1: any =0;

  cashtypess1: any =0;
  cardtypess1: any =0;
  upitypess1: any =0;

  cashamntpaidtypess1: any =0;
  cardamntpaidtypess1: any =0;
  upiamntpaidtypess1: any =0;

  wholesalegsttypes1: any =0;
  primegsttypes1: any =0;
  institutegsttypes1: any =0;
  regulargsttypes1: any =0;
  wholesaleigsttypes1: any =0;
  primeigsttypes1: any =0;
  instituteigsttypes1: any =0;
  regularigsttypes1: any =0;
  wholesaletaxfreetypess1: any =0;
  primetaxfreetypess1: any =0;
  institutetaxfreetypess1: any =0;
  regulartaxfreetypess1: any =0;

  customertypess1arry: { wholesaletypess1: number; primetypess1: number; institutetypess1: number; regulartypess1: number }[]= [];
  taxtypess1arry: { gsttypess1: number; igsttypess1: number; taxfreetypess1: number }[]= [];
  customertaxtypes1arry: {wholesalegsttypes1: number; primegsttypes1: number; institutegsttypes1: number; regulargsttypes1:number; wholesaleigsttypes1:number; primeigsttypes1:number; instituteigsttypes1:number; regularigsttypes1:number; wholesaletaxfreetypess1:number; primetaxfreetypess1:number; institutetaxfreetypess1:number; regulartaxfreetypess1:number}[]= [];
  paymentmodetypess1arry: {cashtypess1: number; cardtypess1: number; upitypess1:number}[]= [];
  paymentmodeamntpaidtypess1arry: {cashamntpaidtypess1: number; cardamntpaidtypess1: number; upiamntpaidtypess1:number}[]= [];

  wholesaletypess2: any =0;
  primetypess2: any =0;
  institutetypess2: any =0;
  regulartypess2: any =0;

  gsttypess2: any =0;
  igsttypess2: any =0;
  taxfreetypess2: any =0;

  cashtypess2: any =0;
  cardtypess2: any =0;
  upitypess2: any =0;

  cashamntpaidtypess2: any =0;
  cardamntpaidtypess2: any =0;
  upiamntpaidtypess2: any =0;

  wholesalegsttypes2: any =0;
  primegsttypes2: any =0;
  institutegsttypes2: any =0;
  regulargsttypes2: any =0;
  wholesaleigsttypes2: any =0;
  primeigsttypes2: any =0;
  instituteigsttypes2: any =0;
  regularigsttypes2: any =0;
  wholesaletaxfreetypess2: any =0;
  primetaxfreetypess2: any =0;
  institutetaxfreetypess2: any =0;
  regulartaxfreetypess2: any =0;

  customertypess2arry: { wholesaletypess2: number; primetypess2: number; institutetypess2: number; regulartypess2: number }[]= [];
  taxtypess2arry: { gsttypess2: number; igsttypess2: number; taxfreetypess2: number }[]= [];
  customertaxtypes2arry: {wholesalegsttypes2: number; primegsttypes2: number; institutegsttypes2: number; regulargsttypes2:number; wholesaleigsttypes2:number; primeigsttypes2:number; instituteigsttypes2:number; regularigsttypes2:number; wholesaletaxfreetypess2:number; primetaxfreetypess2:number; institutetaxfreetypess2:number; regulartaxfreetypess2:number}[]= [];
  paymentmodetypess2arry: {cashtypess2: number; cardtypess2: number; upitypess2:number}[]= [];
  paymentmodeamntpaidtypess2arry: {cashamntpaidtypess2: number; cardamntpaidtypess2: number; upiamntpaidtypess2:number}[]= [];


  wholesaletypesGN: any =0;
  primetypesGN: any =0;
  institutetypesGN: any =0;
  regulartypesGN: any =0;

  gsttypesGN: any =0;
  igsttypesGN: any =0;
  taxfreetypesGN: any =0;

  cashtypesGN: any =0;
  cardtypesGN: any =0;
  upitypesGN: any =0;

  cashamntpaidtypesGN: any =0;
  cardamntpaidtypesGN: any =0;
  upiamntpaidtypesGN: any =0;

  wholesalegsttypeGN: any =0;
  primegsttypeGN: any =0;
  institutegsttypeGN: any =0;
  regulargsttypeGN: any =0;
  wholesaleigsttypeGN: any =0;
  primeigsttypeGN: any =0;
  instituteigsttypeGN: any =0;
  regularigsttypeGN: any =0;
  wholesaletaxfreetypesGN: any =0;
  primetaxfreetypesGN: any =0;
  institutetaxfreetypesGN: any =0;
  regulartaxfreetypesGN: any =0;

  customertypesGNarry: { wholesaletypesGN: number; primetypesGN: number; institutetypesGN: number; regulartypesGN: number }[]= [];
  taxtypesGNarry: { gsttypesGN: number; igsttypesGN: number; taxfreetypesGN: number }[]= [];
  customertaxtypeGNarry: {wholesalegsttypeGN: number; primegsttypeGN: number; institutegsttypeGN: number; regulargsttypeGN:number; wholesaleigsttypeGN:number; primeigsttypeGN:number; instituteigsttypeGN:number; regularigsttypeGN:number; wholesaletaxfreetypesGN:number; primetaxfreetypesGN:number; institutetaxfreetypesGN:number; regulartaxfreetypesGN:number}[]= [];
  paymentmodetypesGNarry: {cashtypesGN: number; cardtypesGN: number; upitypesGN:number}[]= [];
  paymentmodeamntpaidtypesGNarry: {cashamntpaidtypesGN: number; cardamntpaidtypesGN: number; upiamntpaidtypesGN:number}[]= [];

//-------------------------


  message10:boolean;

  st: string;
  step: string;


  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }


  filterValue = {
    dates: String(moment().format('DD-MM-YYYY')),
    datee: String(moment().format('DD-MM-YYYY')),
  };


  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;
  prateaftqntypre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];
  prateaftqnty: { billid:any; prateaftqntypre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalrate: number;
  summy: { billid:any, shop:string, gst: number, rate: number, prate: number, qnty: number, taxtype:string, taxableamt: number, amtaftdisc:number, prateaftqnty:number }[]= [];
  summypre: { billid:any, shop:string, gst: number, rate: number, prate: number, qnty: number, taxtype:string, taxableamt: number, amtaftdisc:number, prateaftqnty:number }[]= [];

  pcollection: any;
  acollection: any;

  todaydate=moment().format('DD-MM-YYYY'); 
  overallsumamtaftdisc: number;
  overallsumtaxableamt: number;
  overallsumamtwithtax: number;
  overallprateaftqnty:number;

  totalbillspre: number;
  totalbills: { date:any;  totalbillspre:number}[]= [];
  overallsumbills: number;



  otxamnts1gst: number;
  otxamnts2gst: number;
  otxamntgngst: number;
  otxamnts1igst: number;
  otxamnts2igst: number;
  otxamntgnigst: number;
  otxamnts1taxfree: number;
  otxamnts2taxfree: number;
  otxamntgntaxfree: number;

  osmamntwitxs1: number;
  osmamntwitxs2: number;
  osmamntwitxgn: number;

  ocshamnts1: number;
  ocshamnts2: number;
  ocshamntgn: number;
  ocrdamnts1: number;
  ocrdamnts2: number;
  ocrdamntgn: number;
  oupiamnts1: number;
  oupiamnts2: number;
  oupiamntgn: number;

  oscashamnts1: number;
  oscardamnts2: number;
  osupimamntgn: number;

  dateRangeFired=false;
  summy2:{ billid:any, shop:string, gst: number, rate: number, taxtype:string, taxableamt: number, amtaftdisc:number }[]= [];
  
  ttcreditdebits1: number=0;
  ttcreditdebits2: number=0;
  ttcreditdebitGN: number=0;
  overallmandisc: number=0;
  overallothercharges: number=0;

  // selectedOptions: string[] = [];

  constructor(private _snackBar: MatSnackBar, private data10: Drawer10Service, private afs: AngularFirestore) { }
  @ViewChildren('allTheseThings') things: QueryList<any>;

  
  ngForRendred() {
    console.log('NgFor is Rendered');
    snipMe();
    }


    
   addorreplace(array, item){
    const i=array.findIndex(_item => _item.billid===item.billid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplace2(array, item){
    const i=array.findIndex(_item => _item.date===item.date);
    if(i>-1) array[i] =item;
    else array.push(item);
   }


   openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition:'top',
      horizontalPosition: 'end',      
      panelClass: 'snackbar'
    });
  }

  ngAfterViewInit() {
//----------------default 'today' selected in range list--------------
// this.datePicker.setStartDate(moment());
// this.datePicker.setEndDate(moment());
// this.datePicker.updateView();
//----------------default 'today' selected in range list--------------
    //this.openSnackBar("Select Date Range First!","OK");
  this.st="step1";
  this.step="step1";

    this.data10.currentMessage10.subscribe(message10 => this.message10 = message10)
console.log("PL"+this.message10);

//this.applyFilter();

//-----------------------newly added----------------------------------------------------------------
// this.acollection = this.afs.collection('Bills').snapshotChanges()
// .pipe( 
//   map(actions => actions.map(a => a.payload.doc.data()))
// );

// this.acollection.subscribe(() => {this.applyFilter()})
//-----------------------newly added----------------------------------------------------------------


  }
         
  rangeClicked(range) {
    console.log('[rangeClicked] range is : ', range);
    
   console.log(range.dates[0].format('DD-MM-YYYY'));
   console.log(range.dates[1].format('DD-MM-YYYY'));
   this.applyFilter2(0,range.dates[0],range.dates[1])
  }
  datesUpdated(range) {
    if (this.dateRangeFired) {
      this.dateRangeFired = false;
      console.log("dateRangeFired not fired")

      console.log('[datesUpdated] range is : ', range);

      console.log(range.startDate.format('DD-MM-YYYY'));
      console.log(range.endDate.format('DD-MM-YYYY'));
      this.applyFilter2(1, range.startDate, range.endDate)
      return;
    }
    this.dateRangeFired = true;

  }
  choosedDate(range) {
    console.log('[choosedDate] range is : ', range);

  console.log(range.startDate.format('DD-MM-YYYY'));
  console.log(range.endDate.format('DD-MM-YYYY'));
  this.applyFilter2(2,range.startDate,range.endDate)
   }

 async applyFilter2(status,sd,ed) {

   // console.log(event.startDate);
   // console.log(event.endDate);
if(status==0){
    this.filterValue.dates = moment(sd).format('DD-MM-YYYY'); 
    this.filterValue.datee = moment(ed).format('DD-MM-YYYY'); 
    console.log("S: "+this.filterValue.dates);
    console.log("E: "+this.filterValue.datee);
}
if(status==1 || status==2){
  this.filterValue.dates = moment(sd).format('DD-MM-YYYY'); 
  this.filterValue.datee = moment(ed).format('DD-MM-YYYY'); 
  console.log("S: "+this.filterValue.dates);
  console.log("E: "+this.filterValue.datee);
}
    this.applyFilter();
  };


  applyFilter(){
         
this.tottaxableamtpre=0;
this.totamtwithtaxpre=0; 
this.sumaftrdiscpre=0; 
this.prateaftqntypre=0;

this.totalamountpre=0;

this.tottaxableamt=[];
this.totamtwithtax=[]; 
this.sumaftrdisc=[]; 
this.totalamount=[];

this.summy=[];

this.totalbillspre=0;
this.totalbills=[];

this.overallsumtaxableamt = 0;
this.overallsumamtwithtax = 0;
this.overallsumamtaftdisc = 0;
this.overallprateaftqnty=0;
this.overallsumbills=0;
      //-------------------------
      this.shopbillsarry=[];
      this.shop1bills =0;
      this.shop2bills =0;
      this.godownbills =0;
      
      this.customertypesarry=[];
      this.wholesaletypes =0;
      this.primetypes =0;
      this.institutetypes =0;
      this.regulartypes =0;
      
      this.taxtypesarry=[];
      this.gsttypes =0;
      this.igsttypes =0;
      this.taxfreetypes =0;
      
      this.paymentmodetypesarry=[];
      this.cashtypes =0;
      this.cardtypes =0;
      this.upitypes =0;

      this.paymentmodeamntpaidtypesarry=[];
      this.cashamntpaidtypes =0;
      this.cardamntpaidtypes =0;
      this.upiamntpaidtypes =0;

      this.customertypess1arry=[];
      this.wholesaletypess1 =0;
      this.primetypess1 =0;
      this.institutetypess1 =0;
      this.regulartypess1 =0;
      
      this.taxtypess1arry=[];
      this.gsttypess1 =0;
      this.igsttypess1 =0;
      this.taxfreetypess1 =0;
      
      this.paymentmodetypess1arry=[];
      this.cashtypess1 =0;
      this.cardtypess1 =0;
      this.upitypess1 =0;

      this.paymentmodeamntpaidtypess1arry=[];
      this.cashamntpaidtypess1 =0;
      this.cardamntpaidtypess1 =0;
      this.upiamntpaidtypess1 =0;

      this.customertaxtypes1arry=[];
      this.wholesalegsttypes1 =0;
      this.primegsttypes1 =0;
      this.institutegsttypes1 =0;
      this.regulargsttypes1 =0;
      this.wholesaleigsttypes1 =0;
      this.primeigsttypes1 =0;
      this.instituteigsttypes1 =0;
      this.regularigsttypes1 =0;
      this.wholesaletaxfreetypess1 =0;
      this.primetaxfreetypess1 =0;
      this.institutetaxfreetypess1 =0;
      this.regulartaxfreetypess1 =0;

      this.customertypess2arry=[];
      this.wholesaletypess2 =0;
      this.primetypess2 =0;
      this.institutetypess2 =0;
      this.regulartypess2 =0;
      
      this.taxtypess2arry=[];
      this.gsttypess2 =0;
      this.igsttypess2 =0;
      this.taxfreetypess2 =0;
      
      this.paymentmodetypess2arry=[];
      this.cashtypess2 =0;
      this.cardtypess2 =0;
      this.upitypess2 =0;

      this.paymentmodeamntpaidtypess2arry=[];
      this.cashamntpaidtypess2 =0;
      this.cardamntpaidtypess2 =0;
      this.upiamntpaidtypess2 =0;

      this.customertaxtypes2arry=[];
      this.wholesalegsttypes2 =0;
      this.primegsttypes2 =0;
      this.institutegsttypes2 =0;
      this.regulargsttypes2 =0;
      this.wholesaleigsttypes2 =0;
      this.primeigsttypes2 =0;
      this.instituteigsttypes2 =0;
      this.regularigsttypes2 =0;
      this.wholesaletaxfreetypess2 =0;
      this.primetaxfreetypess2 =0;
      this.institutetaxfreetypess2 =0;
      this.regulartaxfreetypess2 =0;

      this.customertypesGNarry=[];
      this.wholesaletypesGN =0;
      this.primetypesGN =0;
      this.institutetypesGN =0;
      this.regulartypesGN =0;
      
      this.taxtypesGNarry=[];
      this.gsttypesGN =0;
      this.igsttypesGN =0;
      this.taxfreetypesGN =0;

      this.paymentmodetypesGNarry=[];
      this.cashtypesGN =0;
      this.cardtypesGN =0;
      this.upitypesGN =0;

      this.paymentmodeamntpaidtypesGNarry=[];
      this.cashamntpaidtypesGN =0;
      this.cardamntpaidtypesGN =0;
      this.upiamntpaidtypesGN =0;

      this.customertaxtypeGNarry=[];
      this.wholesalegsttypeGN =0;
      this.primegsttypeGN =0;
      this.institutegsttypeGN =0;
      this.regulargsttypeGN =0;
      this.wholesaleigsttypeGN =0;
      this.primeigsttypeGN =0;
      this.instituteigsttypeGN =0;
      this.regularigsttypeGN =0;
      this.wholesaletaxfreetypesGN =0;
      this.primetaxfreetypesGN =0;
      this.institutetaxfreetypesGN =0;
      this.regulartaxfreetypesGN =0;
      //-------------------------
      
      this.otxamnts1gst = 0;
      this.otxamnts2gst = 0;
  this.otxamntgngst = 0;
  this.otxamnts1igst = 0;
  this.otxamnts2igst = 0;
  this.otxamntgnigst = 0;
  this.otxamnts1taxfree = 0;
  this.otxamnts2taxfree = 0;
  this.otxamntgntaxfree = 0;

  this.osmamntwitxs1 = 0;
  this.osmamntwitxs2 = 0;
  this.osmamntwitxgn = 0;


  this.ocshamnts1 = 0;
  this.ocshamnts2 = 0;
  this.ocshamntgn = 0;
  this.ocrdamnts1 = 0;
  this.ocrdamnts2 = 0;
  this.ocrdamntgn = 0;
  this.oupiamnts1 = 0;
  this.oupiamnts2 = 0;
  this.oupiamntgn = 0;

  this.oscashamnts1 = 0;
  this.oscardamnts2 = 0;
  this.osupimamntgn = 0;
  
  this.ttcreditdebits1=0;
  this.ttcreditdebits2=0;
  this.ttcreditdebitGN=0;

  this.overallmandisc=0;
  this.overallothercharges=0;
  
  this.afs.collection('Ledger',ref=>ref.where("book","==","EXPENSES"))
  .get().toPromise().then(querySnapshot => {


    querySnapshot.forEach(doc => {
      if((moment(doc.data().date2, "DD-MM-YYYY").toDate()) >= (moment(this.filterValue.dates, "DD-MM-YYYY").toDate())
      && (moment(doc.data().date2, "DD-MM-YYYY").toDate()) <= (moment(this.filterValue.datee, "DD-MM-YYYY").toDate()))
      {
        if(doc.data().tag=="SHOP1"){
          this.ttcreditdebits1= this.ttcreditdebits1 +doc.data().debit;
          }

          if(doc.data().tag=="SHOP2"){
            this.ttcreditdebits2= this.ttcreditdebits2 +doc.data().debit;
            }

            if(doc.data().tag=="GODOWN"){
              this.ttcreditdebitGN= this.ttcreditdebitGN +doc.data().debit;
              }
      }
    })
  })


    this.afs.collection('Bills').get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if((moment(doc.data().date2, "DD-MM-YYYY").toDate()) >= (moment(this.filterValue.dates, "DD-MM-YYYY").toDate())
        && (moment(doc.data().date2, "DD-MM-YYYY").toDate()) <= (moment(this.filterValue.datee, "DD-MM-YYYY").toDate())
        && doc.data().billsid)
        {
          console.log(true,moment(doc.data().date2, "DD-MM-YYYY").toDate());

          for(let i=1;i<=doc.data().billsid.length;i++){
   
            this.tottaxableamtpre=0;
            this.totamtwithtaxpre=0; 
            this.sumaftrdiscpre=0; 
            this.prateaftqntypre=0;

            this.totalamountpre=0;
        


            this.afs.collection('Bills').doc(moment(moment(doc.data().date2, "DD-MM-YYYY").toDate()).format("DD-MM-YYYY")).collection('--DISCOUNTS--',ref => ref.where('billid', '==', String(doc.data().billsid[i-1]))).get().toPromise().then(querySnapshot => {
              querySnapshot.forEach(docy => {
                              this.overallmandisc=this.overallmandisc+docy.data().manualdisc
                            })
                          })

                          this.afs.collection('Bills').doc(moment(moment(doc.data().date2, "DD-MM-YYYY").toDate()).format("DD-MM-YYYY")).collection('--OTHERCHARGES--',ref => ref.where('billid', '==', String(doc.data().billsid[i-1]))).get().toPromise().then(querySnapshot => {
                            querySnapshot.forEach(docy => {
                                            this.overallothercharges=this.overallothercharges+docy.data().othercharges
                                          })
                                        })


                                        
          this.afs.collection('Bills').doc(moment(moment(doc.data().date2, "DD-MM-YYYY").toDate()).format("DD-MM-YYYY")).collection('--Id--',ref => ref.where('currentbillid', '==', Number(doc.data().billsid[i-1].substring(4)))).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docy => {
                          this.pcollection = this.afs.collection('Bills').doc(moment(moment(doc.data().date2, "DD-MM-YYYY").toDate()).format("DD-MM-YYYY")).collection(String(docy.data().routinebillid)).snapshotChanges()
                          .pipe( 
                            map(actions => actions.map(a => a.payload.doc.data()))
           );
    
//-------------------------
if (String(doc.data().billsid[i-1]).slice(0, 2) == "S1") {
  this.shop1bills = this.shop1bills+1;
  this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
// if(docy.data().paymentmode == 'CASH'){
  if(docy.data().cashpay != 0){
    this.cashtypess1 = this.cashtypess1+1;
    this.addorreplace(this.paymentmodetypess1arry,{'cashtypess1':this.cashtypess1 , 'cardtypess1':this.cardtypess1, 'upitypess1':this.upitypess1})
   
    this.cashamntpaidtypess1 = this.cashamntpaidtypess1+docy.data().cashpay;
    this.addorreplace(this.paymentmodeamntpaidtypess1arry,{'cashamntpaidtypess1':this.cashamntpaidtypess1 , 'cardamntpaidtypess1':this.cardamntpaidtypess1, 'upiamntpaidtypess1':this.upiamntpaidtypess1})
  }
  // if(docy.data().paymentmode == 'CARD'){
    if(docy.data().cardpay != 0){
    this.cardtypess1 = this.cardtypess1+1;
    this.addorreplace(this.paymentmodetypess1arry,{'cashtypess1':this.cashtypess1 , 'cardtypess1':this.cardtypess1, 'upitypess1':this.upitypess1})
   
    this.cardamntpaidtypess1 = this.cardamntpaidtypess1+docy.data().cardpay;
    this.addorreplace(this.paymentmodeamntpaidtypess1arry,{'cashamntpaidtypess1':this.cashamntpaidtypess1 , 'cardamntpaidtypess1':this.cardamntpaidtypess1, 'upiamntpaidtypess1':this.upiamntpaidtypess1})
  }
  // if(docy.data().paymentmode == 'UPI PAYMENT'){
    if(docy.data().upipay != 0){
    this.upitypess1 = this.upitypess1+1;
    this.addorreplace(this.paymentmodetypess1arry,{'cashtypess1':this.cashtypess1 , 'cardtypess1':this.cardtypess1, 'upitypess1':this.upitypess1})
   
    this.upiamntpaidtypess1 = this.upiamntpaidtypess1+docy.data().upipay;
    this.addorreplace(this.paymentmodeamntpaidtypess1arry,{'cashamntpaidtypess1':this.cashamntpaidtypess1 , 'cardamntpaidtypess1':this.cardamntpaidtypess1, 'upiamntpaidtypess1':this.upiamntpaidtypess1})
   }
  if (String(doc.data().billsid[i-1]).slice(2, 3) == "W") {
    this.wholesaletypess1 = this.wholesaletypess1+1;
    this.addorreplace(this.customertypess1arry,{'wholesaletypess1':this.wholesaletypess1 , 'primetypess1':this.primetypess1, 'institutetypess1':this.institutetypess1, 'regulartypess1':this.regulartypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P") {
    this.primetypess1 = this.primetypess1+1;
    this.addorreplace(this.customertypess1arry,{'wholesaletypess1':this.wholesaletypess1 , 'primetypess1':this.primetypess1, 'institutetypess1':this.institutetypess1, 'regulartypess1':this.regulartypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I") {
    this.institutetypess1 = this.institutetypess1+1;
    this.addorreplace(this.customertypess1arry,{'wholesaletypess1':this.wholesaletypess1 , 'primetypess1':this.primetypess1, 'institutetypess1':this.institutetypess1, 'regulartypess1':this.regulartypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R") {
    this.regulartypess1 = this.regulartypess1+1;
    this.addorreplace(this.customertypess1arry,{'wholesaletypess1':this.wholesaletypess1 , 'primetypess1':this.primetypess1, 'institutetypess1':this.institutetypess1, 'regulartypess1':this.regulartypess1})
  }

  if (String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.gsttypess1 = this.gsttypess1+1;
    this.addorreplace(this.taxtypess1arry,{'gsttypess1':this.gsttypess1 , 'igsttypess1':this.igsttypess1, 'taxfreetypess1':this.taxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.igsttypess1 = this.igsttypess1+1;
    this.addorreplace(this.taxtypess1arry,{'gsttypess1':this.gsttypess1 , 'igsttypess1':this.igsttypess1, 'taxfreetypess1':this.taxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.taxfreetypess1 = this.taxfreetypess1+1;
    this.addorreplace(this.taxtypess1arry,{'gsttypess1':this.gsttypess1 , 'igsttypess1':this.igsttypess1, 'taxfreetypess1':this.taxfreetypess1})
  }

  if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.wholesalegsttypes1 = this.wholesalegsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.primegsttypes1 = this.primegsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.institutegsttypes1 = this.institutegsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.regulargsttypes1 = this.regulargsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.wholesaleigsttypes1 = this.wholesaleigsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.primeigsttypes1 = this.primeigsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.instituteigsttypes1 = this.instituteigsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.regularigsttypes1 = this.regularigsttypes1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.wholesaletaxfreetypess1 = this.wholesaletaxfreetypess1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.primetaxfreetypess1 = this.primetaxfreetypess1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.institutetaxfreetypess1 = this.institutetaxfreetypess1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.regulartaxfreetypess1 = this.regulartaxfreetypess1+1;
    this.addorreplace(this.customertaxtypes1arry,{'wholesalegsttypes1': this.wholesalegsttypes1, 'primegsttypes1': this.primegsttypes1, 'institutegsttypes1': this.institutegsttypes1, 'regulargsttypes1':this.regulargsttypes1, 'wholesaleigsttypes1':this.wholesaleigsttypes1, 'primeigsttypes1':this.primeigsttypes1, 'instituteigsttypes1':this.instituteigsttypes1, 'regularigsttypes1':this.regularigsttypes1, 'wholesaletaxfreetypess1':this.wholesaletaxfreetypess1, 'primetaxfreetypess1':this.primetaxfreetypess1, 'institutetaxfreetypess1':this.institutetaxfreetypess1, 'regulartaxfreetypess1':this.regulartaxfreetypess1})
  }

}
else if (String(doc.data().billsid[i-1]).slice(0, 2) == "S2") {
  this.shop2bills = this.shop2bills+1;
  this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})

  // if(docy.data().paymentmode == 'CASH'){
    if(docy.data().cashpay != 0){
      this.cashtypess2 = this.cashtypess2+1;
      this.addorreplace(this.paymentmodetypess2arry,{'cashtypess2':this.cashtypess2 , 'cardtypess2':this.cardtypess2, 'upitypess2':this.upitypess2})
      
      this.cashamntpaidtypess2 = this.cashamntpaidtypess2+docy.data().cashpay;
      this.addorreplace(this.paymentmodeamntpaidtypess2arry,{'cashamntpaidtypess2':this.cashamntpaidtypess2 , 'cardamntpaidtypess2':this.cardamntpaidtypess2, 'upiamntpaidtypess2':this.upiamntpaidtypess2})
    }
    // if(docy.data().paymentmode == 'CARD'){
      if(docy.data().cardpay != 0){
      this.cardtypess2 = this.cardtypess2+1;
      this.addorreplace(this.paymentmodetypess2arry,{'cashtypess2':this.cashtypess2 , 'cardtypess2':this.cardtypess2, 'upitypess2':this.upitypess2})
      
      this.cardamntpaidtypess2 = this.cardamntpaidtypess2+docy.data().cardpay;
      this.addorreplace(this.paymentmodeamntpaidtypess2arry,{'cashamntpaidtypess2':this.cashamntpaidtypess2 , 'cardamntpaidtypess2':this.cardamntpaidtypess2, 'upiamntpaidtypess2':this.upiamntpaidtypess2})
      }
    // if(docy.data().paymentmode == 'UPI PAYMENT'){
      if(docy.data().upipay != 0){
      this.upitypess2 = this.upitypess2+1;
      this.addorreplace(this.paymentmodetypess2arry,{'cashtypess2':this.cashtypess2 , 'cardtypess2':this.cardtypess2, 'upitypess2':this.upitypess2})
     
      this.upiamntpaidtypess2 = this.upiamntpaidtypess2+docy.data().upipay;
      this.addorreplace(this.paymentmodeamntpaidtypess2arry,{'cashamntpaidtypess2':this.cashamntpaidtypess2 , 'cardamntpaidtypess2':this.cardamntpaidtypess2, 'upiamntpaidtypess2':this.upiamntpaidtypess2})
     }

  if (String(doc.data().billsid[i-1]).slice(2, 3) == "W") {
    this.wholesaletypess2 = this.wholesaletypess2+1;
    this.addorreplace(this.customertypess2arry,{'wholesaletypess2':this.wholesaletypess2 , 'primetypess2':this.primetypess2, 'institutetypess2':this.institutetypess2, 'regulartypess2':this.regulartypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P") {
    this.primetypess2 = this.primetypess2+1;
    this.addorreplace(this.customertypess2arry,{'wholesaletypess2':this.wholesaletypess2 , 'primetypess2':this.primetypess2, 'institutetypess2':this.institutetypess2, 'regulartypess2':this.regulartypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I") {
    this.institutetypess2 = this.institutetypess2+1;
    this.addorreplace(this.customertypess2arry,{'wholesaletypess2':this.wholesaletypess2 , 'primetypess2':this.primetypess2, 'institutetypess2':this.institutetypess2, 'regulartypess2':this.regulartypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R") {
    this.regulartypess2 = this.regulartypess2+1;
    this.addorreplace(this.customertypess2arry,{'wholesaletypess2':this.wholesaletypess2 , 'primetypess2':this.primetypess2, 'institutetypess2':this.institutetypess2, 'regulartypess2':this.regulartypess2})
  }

  if (String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.gsttypess2 = this.gsttypess2+1;
    this.addorreplace(this.taxtypess2arry,{'gsttypess2':this.gsttypess2 , 'igsttypess2':this.igsttypess2, 'taxfreetypess2':this.taxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.igsttypess2 = this.igsttypess2+1;
    this.addorreplace(this.taxtypess2arry,{'gsttypess2':this.gsttypess2 , 'igsttypess2':this.igsttypess2, 'taxfreetypess2':this.taxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.taxfreetypess2 = this.taxfreetypess2+1;
    this.addorreplace(this.taxtypess2arry,{'gsttypess2':this.gsttypess2 , 'igsttypess2':this.igsttypess2, 'taxfreetypess2':this.taxfreetypess2})
  }

  if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.wholesalegsttypes2 = this.wholesalegsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.primegsttypes2 = this.primegsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.institutegsttypes2 = this.institutegsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.regulargsttypes2 = this.regulargsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.wholesaleigsttypes2 = this.wholesaleigsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.primeigsttypes2 = this.primeigsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.instituteigsttypes2 = this.instituteigsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.regularigsttypes2 = this.regularigsttypes2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.wholesaletaxfreetypess2 = this.wholesaletaxfreetypess2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.primetaxfreetypess2 = this.primetaxfreetypess2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.institutetaxfreetypess2 = this.institutetaxfreetypess2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.regulartaxfreetypess2 = this.regulartaxfreetypess2+1;
    this.addorreplace(this.customertaxtypes2arry,{'wholesalegsttypes2': this.wholesalegsttypes2, 'primegsttypes2': this.primegsttypes2, 'institutegsttypes2': this.institutegsttypes2, 'regulargsttypes2':this.regulargsttypes2, 'wholesaleigsttypes2':this.wholesaleigsttypes2, 'primeigsttypes2':this.primeigsttypes2, 'instituteigsttypes2':this.instituteigsttypes2, 'regularigsttypes2':this.regularigsttypes2, 'wholesaletaxfreetypess2':this.wholesaletaxfreetypess2, 'primetaxfreetypess2':this.primetaxfreetypess2, 'institutetaxfreetypess2':this.institutetaxfreetypess2, 'regulartaxfreetypess2':this.regulartaxfreetypess2})
  }

}
else if (String(doc.data().billsid[i-1]).slice(0, 2) == "GN") {
  this.godownbills = this.godownbills+1;
  this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})

  // if(docy.data().paymentmode == 'CASH'){
    if(docy.data().cashpay != 0){
      this.cashtypesGN = this.cashtypesGN+1;
      this.addorreplace(this.paymentmodetypesGNarry,{'cashtypesGN':this.cashtypesGN , 'cardtypesGN':this.cardtypesGN, 'upitypesGN':this.upitypesGN})
    
      this.cashamntpaidtypesGN = this.cashamntpaidtypesGN+docy.data().cashpay;
      this.addorreplace(this.paymentmodeamntpaidtypesGNarry,{'cashamntpaidtypesGN':this.cashamntpaidtypesGN , 'cardamntpaidtypesGN':this.cardamntpaidtypesGN, 'upiamntpaidtypesGN':this.upiamntpaidtypesGN})
    }
    // if(docy.data().paymentmode == 'CARD'){
      if(docy.data().cardpay != 0){
      this.cardtypesGN = this.cardtypesGN+1;
      this.addorreplace(this.paymentmodetypesGNarry,{'cashtypesGN':this.cashtypesGN , 'cardtypesGN':this.cardtypesGN, 'upitypesGN':this.upitypesGN})
    
      this.cardamntpaidtypesGN = this.cardamntpaidtypesGN+docy.data().cardpay;
      this.addorreplace(this.paymentmodeamntpaidtypesGNarry,{'cashamntpaidtypesGN':this.cashamntpaidtypesGN , 'cardamntpaidtypesGN':this.cardamntpaidtypesGN, 'upiamntpaidtypesGN':this.upiamntpaidtypesGN})
    }
    // if(docy.data().paymentmode == 'UPI PAYMENT'){
      if(docy.data().upipay != 0){
      this.upitypesGN = this.upitypesGN+1;
      this.addorreplace(this.paymentmodetypesGNarry,{'cashtypesGN':this.cashtypesGN , 'cardtypesGN':this.cardtypesGN, 'upitypesGN':this.upitypesGN})
    
      this.upiamntpaidtypesGN = this.upiamntpaidtypesGN+docy.data().upipay;
      this.addorreplace(this.paymentmodeamntpaidtypesGNarry,{'cashamntpaidtypesGN':this.cashamntpaidtypesGN , 'cardamntpaidtypesGN':this.cardamntpaidtypesGN, 'upiamntpaidtypesGN':this.upiamntpaidtypesGN})
    }

  if (String(doc.data().billsid[i-1]).slice(2, 3) == "W") {
    this.wholesaletypesGN = this.wholesaletypesGN+1;
    this.addorreplace(this.customertypesGNarry,{'wholesaletypesGN':this.wholesaletypesGN , 'primetypesGN':this.primetypesGN, 'institutetypesGN':this.institutetypesGN, 'regulartypesGN':this.regulartypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P") {
    this.primetypesGN = this.primetypesGN+1;
    this.addorreplace(this.customertypesGNarry,{'wholesaletypesGN':this.wholesaletypesGN , 'primetypesGN':this.primetypesGN, 'institutetypesGN':this.institutetypesGN, 'regulartypesGN':this.regulartypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I") {
    this.institutetypesGN = this.institutetypesGN+1;
    this.addorreplace(this.customertypesGNarry,{'wholesaletypesGN':this.wholesaletypesGN , 'primetypesGN':this.primetypesGN, 'institutetypesGN':this.institutetypesGN, 'regulartypesGN':this.regulartypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R") {
    this.regulartypesGN = this.regulartypesGN+1;
    this.addorreplace(this.customertypesGNarry,{'wholesaletypesGN':this.wholesaletypesGN , 'primetypesGN':this.primetypesGN, 'institutetypesGN':this.institutetypesGN, 'regulartypesGN':this.regulartypesGN})
  }

  if (String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.gsttypesGN = this.gsttypesGN+1;
    this.addorreplace(this.taxtypesGNarry,{'gsttypesGN':this.gsttypesGN , 'igsttypesGN':this.igsttypesGN, 'taxfreetypesGN':this.taxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.igsttypesGN = this.igsttypesGN+1;
    this.addorreplace(this.taxtypesGNarry,{'gsttypesGN':this.gsttypesGN , 'igsttypesGN':this.igsttypesGN, 'taxfreetypesGN':this.taxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.taxfreetypesGN = this.taxfreetypesGN+1;
    this.addorreplace(this.taxtypesGNarry,{'gsttypesGN':this.gsttypesGN , 'igsttypesGN':this.igsttypesGN, 'taxfreetypesGN':this.taxfreetypesGN})
  }

  if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.wholesalegsttypeGN = this.wholesalegsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.primegsttypeGN = this.primegsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.institutegsttypeGN = this.institutegsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
    this.regulargsttypeGN = this.regulargsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.wholesaleigsttypeGN = this.wholesaleigsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.primeigsttypeGN = this.primeigsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.instituteigsttypeGN = this.instituteigsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
    this.regularigsttypeGN = this.regularigsttypeGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "W" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.wholesaletaxfreetypesGN = this.wholesaletaxfreetypesGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.primetaxfreetypesGN = this.primetaxfreetypesGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.institutetaxfreetypesGN = this.institutetaxfreetypesGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }
  else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R" && String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
    this.regulartaxfreetypesGN = this.regulartaxfreetypesGN+1;
    this.addorreplace(this.customertaxtypeGNarry,{'wholesalegsttypeGN': this.wholesalegsttypeGN, 'primegsttypeGN': this.primegsttypeGN, 'institutegsttypeGN': this.institutegsttypeGN, 'regulargsttypeGN':this.regulargsttypeGN, 'wholesaleigsttypeGN':this.wholesaleigsttypeGN, 'primeigsttypeGN':this.primeigsttypeGN, 'instituteigsttypeGN':this.instituteigsttypeGN, 'regularigsttypeGN':this.regularigsttypeGN, 'wholesaletaxfreetypesGN':this.wholesaletaxfreetypesGN, 'primetaxfreetypesGN':this.primetaxfreetypesGN, 'institutetaxfreetypesGN':this.institutetaxfreetypesGN, 'regulartaxfreetypesGN':this.regulartaxfreetypesGN})
  }

}

if (String(doc.data().billsid[i-1]).slice(2, 3) == "W") {
  this.wholesaletypes = this.wholesaletypes+1;
  this.addorreplace(this.customertypesarry,{'wholesaletypes':this.wholesaletypes , 'primetypes':this.primetypes, 'institutetypes':this.institutetypes, 'regulartypes':this.regulartypes})
}
else if (String(doc.data().billsid[i-1]).slice(2, 3) == "P") {
  this.primetypes = this.primetypes+1;
  this.addorreplace(this.customertypesarry,{'wholesaletypes':this.wholesaletypes , 'primetypes':this.primetypes, 'institutetypes':this.institutetypes, 'regulartypes':this.regulartypes})
}
else if (String(doc.data().billsid[i-1]).slice(2, 3) == "I") {
  this.institutetypes = this.institutetypes+1;
  this.addorreplace(this.customertypesarry,{'wholesaletypes':this.wholesaletypes , 'primetypes':this.primetypes, 'institutetypes':this.institutetypes, 'regulartypes':this.regulartypes})
}
else if (String(doc.data().billsid[i-1]).slice(2, 3) == "R") {
  this.regulartypes = this.regulartypes+1;
  this.addorreplace(this.customertypesarry,{'wholesaletypes':this.wholesaletypes , 'primetypes':this.primetypes, 'institutetypes':this.institutetypes, 'regulartypes':this.regulartypes})
}

if (String(doc.data().billsid[i-1]).slice(3, 4) == "G") {
  this.gsttypes = this.gsttypes+1;
  this.addorreplace(this.taxtypesarry,{'gsttypes':this.gsttypes , 'igsttypes':this.igsttypes, 'taxfreetypes':this.taxfreetypes})
}
else if (String(doc.data().billsid[i-1]).slice(3, 4) == "I") {
  this.igsttypes = this.igsttypes+1;
  this.addorreplace(this.taxtypesarry,{'gsttypes':this.gsttypes , 'igsttypes':this.igsttypes, 'taxfreetypes':this.taxfreetypes})
}
else if (String(doc.data().billsid[i-1]).slice(3, 4) == "T") {
  this.taxfreetypes = this.taxfreetypes+1;
  this.addorreplace(this.taxtypesarry,{'gsttypes':this.gsttypes , 'igsttypes':this.igsttypes, 'taxfreetypes':this.taxfreetypes})
}
//-------------------------

this.pcollection.subscribe((posts) => {


            console.log("posty:"+posts); //This is the array of posts, not an observable
        
            if(doc.data().billsid[i-1].substring(3,4)=='G'){
            this.summypre=(_(posts).groupBy('cgst')
              .map((objs, key) => {
                  return {
                'billid':doc.data().billsid[i-1].substring(4),
                'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'taxtype':'gst',
                'taxableamt':2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                'prateaftqnty': (_.sumBy(objs, ('purchaserateafterqnty'))),  
              }
              })
              .value()
            );
            }
            if(doc.data().billsid[i-1].substring(3,4)=='I'){
              this.summypre=(_(posts).groupBy('igst')
                .map((objs, key) => {
                    return {
                  'billid':doc.data().billsid[i-1].substring(4),
                  'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                  'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                  'taxtype':'igst',
                  'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                  'prateaftqnty': (_.sumBy(objs, ('purchaserateafterqnty'))),  
                }
                })
                .value()
              );
              }
              if(doc.data().billsid[i-1].substring(3,4)=='T'){
                this.summypre=(_(posts).groupBy('taxfree')
                  .map((objs, key) => {
                      return {
                    'billid':doc.data().billsid[i-1].substring(4),
                    'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                    'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                    'taxtype':'taxfree',
                    'taxableamt':(parseFloat('0.00')),
                    'prateaftqnty': (_.sumBy(objs, ('purchaserateafterqnty'))),  
                  }
                  })
                  .value()
                );
                }
               
                
            this.summy=this.summy.concat(this.summypre);

            //if yot want to delete it uncomment above line
//-----newly added--------
//this.summy= _.unionBy(this.summy, this.summypre, "billid");
//-----newly added--------

//-----newly added--------

// if(this.overallsumbills == 2 * (this.shop1bills+this.shop2bills+this.godownbills)){
//   console.log("ESCAPE");
// this.wholesalegsttypes1=this.wholesalegsttypes1 / 2;
// this.wholesaleigsttypes1=this.wholesaleigsttypes1 /2;
// this.wholesaletaxfreetypess1=this.wholesaletaxfreetypess1 /2;
// this.institutegsttypes1=this.institutegsttypes1 / 2;
// this.instituteigsttypes1=this.instituteigsttypes1 / 2;
// this.institutetaxfreetypess1=this.institutetaxfreetypess1 / 2;
// this.primegsttypes1=this.primegsttypes1 / 2;
// this.primeigsttypes1=this.primeigsttypes1 / 2;
// this.primetaxfreetypess1=this.primetaxfreetypess1 / 2;
// this.regulargsttypes1=this.regulargsttypes1 / 2;
// this.regularigsttypes1=this.regularigsttypes1 / 2;
// this.regulartaxfreetypess1=this.regulartaxfreetypess1 / 2;
// this.shop1bills=this.shop1bills / 2;
// this.wholesalegsttypes2=this.wholesalegsttypes2 / 2;
// this.wholesaleigsttypes2=this.wholesaleigsttypes2 /2;
// this.wholesaletaxfreetypess2=this.wholesaletaxfreetypess2 /2;
// this.institutegsttypes2=this.institutegsttypes2 / 2;
// this.instituteigsttypes2=this.instituteigsttypes2 / 2;
// this.institutetaxfreetypess2=this.institutetaxfreetypess2 / 2;
// this.primegsttypes2=this.primegsttypes2 / 2;
// this.primeigsttypes2=this.primeigsttypes2 / 2;
// this.primetaxfreetypess2=this.primetaxfreetypess2 / 2;
// this.regulargsttypes2=this.regulargsttypes2 / 2;
// this.regularigsttypes2=this.regularigsttypes2 / 2;
// this.regulartaxfreetypess2=this.regulartaxfreetypess2 / 2;
// this.shop2bills=this.shop2bills / 2;
// this.wholesalegsttypeGN=this.wholesalegsttypeGN / 2;
// this.wholesaleigsttypeGN=this.wholesaleigsttypeGN /2;
// this.wholesaletaxfreetypesGN=this.wholesaletaxfreetypesGN /2;
// this.institutegsttypeGN=this.institutegsttypeGN / 2;
// this.instituteigsttypeGN=this.instituteigsttypeGN / 2;
// this.institutetaxfreetypesGN=this.institutetaxfreetypesGN / 2;
// this.primegsttypeGN=this.primegsttypeGN / 2;
// this.primeigsttypeGN=this.primeigsttypeGN / 2;
// this.primetaxfreetypesGN=this.primetaxfreetypesGN / 2;
// this.regulargsttypeGN=this.regulargsttypeGN / 2;
// this.regularigsttypeGN=this.regularigsttypeGN / 2;
// this.regulartaxfreetypesGN=this.regulartaxfreetypesGN / 2;
// this.godownbills=this.godownbills / 2;
// }
//-----newly added--------

            this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
            this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
            this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
            this.prateaftqntypre=this.summypre.reduce((sum2, item2) => sum2 + item2.prateaftqnty, 0);

            this.addorreplace(this.tottaxableamt,{'billid':doc.data().billsid[i-1].substring(4), tottaxableamtpre:this.tottaxableamtpre})
            this.addorreplace(this.totamtwithtax,{'billid':doc.data().billsid[i-1].substring(4), totamtwithtaxpre:this.totamtwithtaxpre})
            this.addorreplace(this.sumaftrdisc,{'billid':doc.data().billsid[i-1].substring(4), sumaftrdiscpre:this.sumaftrdiscpre})
            this.addorreplace(this.prateaftqnty,{'billid':doc.data().billsid[i-1].substring(4), prateaftqntypre:this.prateaftqntypre})

            console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
            console.log("SD: "+this.filterValue.dates);
            console.log("ED: "+this.filterValue.datee);
              console.log("SUMMYYY:"+JSON.stringify(this.summy));
        
        
              this.overallsumtaxableamt = this.summy.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
              this.overallsumamtwithtax = this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.overallsumtaxableamt;
        this.overallsumamtaftdisc = this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
        this.overallprateaftqnty = this.summy.reduce((sum2, item2) => sum2 + item2.prateaftqnty, 0);
        
      console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+this.overallsumamtaftdisc);

      this.totalbillspre=doc.data().billsid.length;
      this.addorreplace2(this.totalbills,{'date':doc.data().date2, totalbillspre:this.totalbillspre})
      this.overallsumbills = this.totalbills.reduce((sum2, item2) => sum2 + item2.totalbillspre, 0);


       this.otxamnts1gst=this.summy.filter(item => item.shop === "S1" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2gst=this.summy.filter(item => item.shop === "S2" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgngst=this.summy.filter(item => item.shop === "GN" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts1igst=this.summy.filter(item => item.shop === "S1" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2igst=this.summy.filter(item => item.shop === "S2" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgnigst=this.summy.filter(item => item.shop === "GN" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts1taxfree=this.summy.filter(item => item.shop === "S1" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2taxfree=this.summy.filter(item => item.shop === "S2" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgntaxfree=this.summy.filter(item => item.shop === "GN" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);


       this.osmamntwitxs1=this.summy.filter(item => item.shop === "S1").reduce((a, b) => a + b.amtaftdisc, 0);
       this.osmamntwitxs2=this.summy.filter(item => item.shop === "S2").reduce((a, b) => a + b.amtaftdisc, 0);
       this.osmamntwitxgn=this.summy.filter(item => item.shop === "GN").reduce((a, b) => a + b.amtaftdisc, 0);

    })

  })
})

      }
      
        }
        else{
          console.log(false,moment(doc.data().date2, "DD-MM-YYYY").toDate());
        }
      
  })
})


  }

}

