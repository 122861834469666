import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer4Service {

  private messageSource4 = new BehaviorSubject(true); //false is 'default message'
  currentMessage4 = this.messageSource4.asObservable();

  constructor() { }

  changeMessage4(message4: boolean) {
    this.messageSource4.next(message4)
  }

}