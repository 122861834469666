import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecommerceproductlist',
  templateUrl: './ecommerceproductlist.component.html',
  styleUrls: ['./ecommerceproductlist.component.css']
})
export class EcommerceproductlistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
