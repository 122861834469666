import { Component, OnInit, PipeTransform, Pipe, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';
import * as _ from 'lodash';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
  selector: 'app-ecategorygroup',
  templateUrl: './ecategorygroup.component.html',
  styleUrls: ['./ecategorygroup.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})

export class EcategorygroupComponent implements AfterViewInit, OnInit {
  firstFormGroup: FormGroup;


  showMainContent:Boolean=true;
  selectedOptions: string[] = [];

  sportscollection: { index:any, sportname: any }[]= [];

  constructor(private afs: AngularFirestore, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.sportscollection= 
      [{index:'AH', sportname: "Air Hockey"},
      {index:'AR', sportname: "Archery"},
      {index:'AL', sportname: "Athletics"},
      {index:'BD', sportname: "Badminton"},
      {index:'BB', sportname: "Baseball"},
      {index:'BK', sportname: "Basketball"},
      {index:'BT', sportname: "Boating"},
      {index:'BM', sportname: "Boomerang"},
      {index:'BX', sportname: "Boxing"},
      {index:'BL', sportname: "Bowling"},
      {index:'CH', sportname: "Camping & Hiking"},
      {index:'CM', sportname: "Carrom"},
      {index:'CH', sportname: "Chess"},
      {index:'CR', sportname: "Cricket"},
      {index:'CL', sportname: "Climbing"},
      {index:'CY', sportname: "Cycling"},
      {index:'DR', sportname: "Darts"},
      {index:'DT', sportname: "Discus Throw"},
      {index:'DV', sportname: "Diving"},
      {index:'FI', sportname: "Fishing"},
      {index:'FB', sportname: "Football"},
      {index:'GF', sportname: "Golf"},
      {index:'HB', sportname: "Handball"},
      {index:'HH', sportname: "Hobby Hunting"},
      {index:'HY', sportname: "Hockey"},
      {index:'HR', sportname: "Horse Riding"},
      {index:'JT', sportname: "Jumping Trainers"},
      {index:'KF', sportname: "Kite Flying"},
      {index:'NB', sportname: "Netball"},
      {index:'BS', sportname: "Other Ball Sports"},
      {index:'PL', sportname: "Pool"},
      {index:'RY', sportname: "Rugby"},
      {index:'RU', sportname: "Running"},
      {index:'SC', sportname: "Scooters"},
      {index:'SH', sportname: "Shooting"},
      {index:'SK', sportname: "Skating"},
      {index:'SA', sportname: "Sport Accessories"},
      {index:'SQ', sportname: "Squash"},
      {index:'SW', sportname: "Swimming"},
      {index:'VB', sportname: "Volleyball"},
      {index:'TT', sportname: "Table Tennis"},
      {index:'TE', sportname: "Tennis"},
      {index:'TB', sportname: "Throwball"},
    
    ]
   ;
  }
  ngAfterViewInit(){
    
  };
}
