import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { Drawer11Service } from "../../assets/services/drawer11.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog} from '@angular/material';
import * as XLSX from 'xlsx'; 

declare function snipMe(): any;


@Component({
  selector: 'reportdetails',
  templateUrl: './reportdetails.component.html',
  styleUrls: ['./reportdetails.component.css']
})

export class ReportdetailsComponent implements AfterViewInit {
  //----------------default 'today' selected in range list--------------
  @ViewChild(DaterangepickerComponent, {static: true}) private datePicker: DaterangepickerComponent;
  //----------------default 'today' selected in range list--------------

  displayedColumns = ['gstin','billno','date', 'amountwithtax', 'amount', 'taxamnt', 'igst','cgst','sgst', 'igsta','cgsta', 'sgsta'];

  dataSource; 
  
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  totalValue: any;

  value=0; //progressbar

  message11:boolean;

  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  myary:{'gstin': string, 'billno': string, 'date': string, 'amountwithtax': number, 'amount':number, 'taxamnt':number, 'igst':number, 'cgst':number, 'sgst':number, 'igsta':number, 'cgsta':number, 'sgsta':number}[]= []; //declare before constructor

  filterValue = {
    name: '',
    dates: String(moment().format('DD-MM-YYYY')),
    datee: String(moment().format('DD-MM-YYYY')),
  };

  dateRangeFired=false;

  
  blcollection: any;
  search: string;
  newary: any[];

  // selectedOptions: string[] = [];

  constructor(private _snackBar: MatSnackBar,private data11: Drawer11Service, private afs: AngularFirestore) { }
  @ViewChildren('allTheseThings') things: QueryList<any>;

  todaydate=moment().format('DD-MM-YYYY'); 

  
  ngForRendred() {
    console.log('NgFor is Rendered');
    snipMe();
    }


    
   addorreplace(array, item){
    const i=array.findIndex(_item => _item.billno===item.billno);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplace2(array, item){
    const i=array.findIndex(_item => _item.date===item.date);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition:'top',
      horizontalPosition: 'end',      
      panelClass: 'snackbar'
    });
  }

  ngAfterViewInit() {


//newly added             
this.tottaxableamtpre=0;
this.totamtwithtaxpre=0; 
this.sumaftrdiscpre=0; 

this.totalamountpre=0;

this.tottaxableamt=[];
this.totamtwithtax=[]; 
this.sumaftrdisc=[]; 
this.totalamount=[];

this.summy=[];
this.aryaddressdetails=[];
//newly added             


    //----------------default 'today' selected in range list--------------
// this.datePicker.setStartDate(moment());
// this.datePicker.setEndDate(moment());
// this.datePicker.updateView();
//----------------default 'today' selected in range list--------------
    this.openSnackBar("Select Date Range First!","OK");


this.data11.currentMessage11.subscribe(message11 => this.message11 = message11)
console.log("PL"+this.message11);



this.dataSource = new MatTableDataSource(this.myary); 
   this.dataSource.sort = this.sort;
  // this.dataSource.paginator=this.paginator;

  //It searches modelno column. To search in entire table skip it
  this.dataSource.filterPredicate = (data, filter) =>
  (data.billno.indexOf(filter) !== -1);
  //----------------------------------------------------------------------
  console.log((this.dataSource));

  }
         
  rangeClicked(range) {
    console.log('[rangeClicked] range is : ', range);
    
   console.log(range.dates[0].format('DD-MM-YYYY'));
   console.log(range.dates[1].format('DD-MM-YYYY'));
   this.applyFilter2(0,range.dates[0],range.dates[1])
  }
  datesUpdated(range) {
    if (this.dateRangeFired) {
      this.dateRangeFired = false;
      console.log("dateRangeFired not fired")

      console.log('[datesUpdated] range is : ', range);

      console.log(range.startDate.format('DD-MM-YYYY'));
      console.log(range.endDate.format('DD-MM-YYYY'));
      this.applyFilter2(1, range.startDate, range.endDate)
      return;
    }
    this.dateRangeFired = true;

  }
  choosedDate(range) {
    console.log('[choosedDate] range is : ', range);

  console.log(range.startDate.format('DD-MM-YYYY'));
  console.log(range.endDate.format('DD-MM-YYYY'));
  this.applyFilter2(2,range.startDate,range.endDate)
   }

 async applyFilter2(status,sd,ed) {
  // this.aryaddressdetails=[];

   // console.log(event.startDate);
   // console.log(event.endDate);
if(status==0){
    this.filterValue.dates = moment(sd).format('DD-MM-YYYY'); 
    this.filterValue.datee = moment(ed).format('DD-MM-YYYY'); 
    console.log("S: "+this.filterValue.dates);
    console.log("E: "+this.filterValue.datee);
}
if(status==1 || status==2){
  this.filterValue.dates = moment(sd).format('DD-MM-YYYY'); 
  this.filterValue.datee = moment(ed).format('DD-MM-YYYY'); 
  console.log("S: "+this.filterValue.dates);
  console.log("E: "+this.filterValue.datee);
}
    this.applyFilter('');
    
  };


  
// getTotalDebit(){
//   if(this.dataSource === undefined){return 0;}
//   else if(this.dataSource != undefined){let tdebit = _.sumBy(this.dataSource.filteredData, function (a) {
//      return parseInt(a.debit);
// });
// return tdebit;
// }
// }


// getTotalCredit(){
//   if(this.dataSource === undefined){return 0;}
//   else if(this.dataSource != undefined){let tcredit = _.sumBy(this.dataSource.filteredData, function (a) {
//      return parseInt(a.credit);
// });
// return tcredit;
// }
// }

// getTotalDC(){
//   if(this.dataSource === undefined){return 0;}
//   else if(this.dataSource != undefined){
//     let tdebit = _.sumBy(this.dataSource.filteredData, function (a) {
//      return parseInt(a.debit);
// });
// let tcredit = _.sumBy(this.dataSource.filteredData, function (a) {
//   return parseInt(a.credit);
// });
// let r =tdebit-tcredit;
// return r;

// }
// }

  applyFilter(filterValue: string) {
    
         
this.tottaxableamtpre=0;
this.totamtwithtaxpre=0; 
this.sumaftrdiscpre=0; 

this.totalamountpre=0;

this.tottaxableamt=[];
this.totamtwithtax=[]; 
this.sumaftrdisc=[]; 
this.totalamount=[];

this.summy=[];
this.aryaddressdetails=[];

    this.filterValue.name = filterValue.trim(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filterValue);  
    this.dataSource.filter = filterValue; 
    this.newary=[];
    this.afs.collection('Bills').get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if((moment(doc.data().date2, "DD-MM-YYYY").toDate()) >= (moment(this.filterValue.dates, "DD-MM-YYYY").toDate())
        && (moment(doc.data().date2, "DD-MM-YYYY").toDate()) <= (moment(this.filterValue.datee, "DD-MM-YYYY").toDate())
        && doc.data().billsid)
        {
          for(let i=0;i<doc.data().billsid.length;i++){

           
            
  this.afs.collection('Bills').doc(doc.data().date2).collection('--stats--',ref => ref.where('billrefno', '==', doc.data().billsrefno[i])).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {

    this.aryaddressdetails.push({
         'billono':docy.data().billrefno,
         'customerGst': docy.data().customerGst,
          });
        });
        console.log(this.aryaddressdetails)
      return false;         

  });



            this.calc(doc.data().date2,doc.data().billsid[i],doc.data().billsrefno[i])
            console.log(this.newary);

          }
                  }
                  else{
          // this.myary=[];
          // console.log(this.myary);
          console.log("else");
                  }
                })
              })
    //           this.getTotalDebit();
    // this.getTotalCredit();
            }



            tottaxableamtpre: number;
            totamtwithtaxpre: number;
            sumaftrdiscpre: number;
          
            tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
            totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
            sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];
          
            totalamountpre: number;
            totalamount: { billid:any; totalamountpre: number }[]= [];
          
            totalrate: number;
            summy: { billid:any, date:any, gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];
            summypre: { billid:any, date:any, gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];
          
            pcollection: any;

            arybilldetails:{
              billid:any;
              code:string;
              productname: string;
              hsncode: string;
              discount: number;
              qnty : number;
              unit2 : string;
              rate: number;
              cgst: number;
              sgst: number;
              igst:number;
            }[]=[];

            aryaddressdetails:{
              billono:any;
              customerGst: string;            
            }[]=[];



calc(pdate: any,pbillid: string,pbillrefno: string){      

    this.tottaxableamtpre=0;
    this.totamtwithtaxpre=0; 
    this.sumaftrdiscpre=0; 

    this.totalamountpre=0;

console.log("pbillid:"+pbillid)
  this.afs.collection('Bills').doc(pdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(pbillid.substring(4)))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
        this.pcollection =   this.afs.collection('Bills').doc(pdate).collection(String(docy.data().routinebillid)).snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
 
  this.pcollection.subscribe((posts) => {
      
    //  this.summy=this.summy.filter(item => item.billid !== pbillid)


    this.summy=_.uniqWith(this.summy,(obj1,obj2)=>
    obj1.billid === obj2.bilid&&
    obj1.amtaftdisc === obj2.amtaftdisc
    );

    if(pbillid.substring(3,4)=='G'){
    this.summypre=(_(posts).groupBy('cgst')
      .map((objs, key) => {
          return {
        'billid':pbillid,
        'date':pdate,
        'billrefno':pbillrefno,
        'cgst': key,             
        'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
        'gstp':(key*2),
        'cgstp':(parseFloat(key)),
        'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'sgstp':(parseFloat(key)),
        'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'igstp':(parseFloat('0')),
        'igsta':(parseFloat('0.00')),
        'taxableamt': 2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
          }
      })
      .value()
    );

    this.summy=this.summy.concat(this.summypre);

    
    this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
    this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
    this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

    this.addorreplace(this.tottaxableamt,{'billid':pbillid, tottaxableamtpre:this.tottaxableamtpre})
    this.addorreplace(this.totamtwithtax,{'billid':pbillid, totamtwithtaxpre:this.totamtwithtaxpre})
    this.addorreplace(this.sumaftrdisc,{'billid':pbillid, sumaftrdiscpre:this.sumaftrdiscpre})
    
      this.summy=_.uniqWith(this.summy,_.isEqual);

    }

      if(pbillid.substring(3,4)=='I'){
        this.summypre=(_(posts).groupBy('igst')
        .map((objs, key) => {
          return {
              'billid':pbillid,
              'date':pdate,
              'billrefno':pbillrefno,
              'igst': key,
              'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
              'gstp':(0),
              'cgstp':(parseFloat('0')),
              'cgsta':(parseFloat('0.00')),
              'sgstp':(parseFloat('0')),
              'sgsta':(parseFloat('0.00')),
              'igstp':(parseFloat(key)),
              'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
              'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
               }
      })
      .value()
        );
        this.summy=this.summy.concat(this.summypre);

        
        this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
        this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
        this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
  
        this.addorreplace(this.tottaxableamt,{'billid':pbillid, tottaxableamtpre:this.tottaxableamtpre})
        this.addorreplace(this.totamtwithtax,{'billid':pbillid, totamtwithtaxpre:this.totamtwithtaxpre})
        this.addorreplace(this.sumaftrdisc,{'billid':pbillid, sumaftrdiscpre:this.sumaftrdiscpre})
              
        this.summy=_.uniqWith(this.summy,_.isEqual);

        }

        if(pbillid.substring(3,4)=='T'){
          this.summypre=(_(posts).groupBy('taxfree')
          .map((objs, key) => {
            return {
                'billid':pbillid,
                'date':pdate,
                'billrefno':pbillrefno,
                'taxfree': key,
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'gstp':(0),
                'cgstp':(parseFloat('0')),
                'cgsta':(parseFloat('0.00')),
                'sgstp':(parseFloat('0')),
                'sgsta':(parseFloat('0.00')),
                'igstp':(parseFloat('0')),
                'igsta':(parseFloat('0.00')),
                'taxableamt':(parseFloat('0.00')),
                 }
        })
        .value()
          );
          this.summy=this.summy.concat(this.summypre);
          
          this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
          this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
          this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

          this.addorreplace(this.tottaxableamt,{'billid':pbillid, tottaxableamtpre:this.tottaxableamtpre})
          this.addorreplace(this.totamtwithtax,{'billid':pbillid, totamtwithtaxpre:this.totamtwithtaxpre})
          this.addorreplace(this.sumaftrdisc,{'billid':pbillid, sumaftrdiscpre:this.sumaftrdiscpre})

          this.summy=_.uniqWith(this.summy,_.isEqual);

          }

    let ratetotal = 0;
    let amounttotal = 0;
  for (var i = 0; i < posts.length; i++) {
      if (posts[i].rate) {
          ratetotal += posts[i].rate;
          this.totalrate = ratetotal;
          amounttotal += (posts[i].rate * posts[i].qnty);
          this.totalamountpre = amounttotal;
      }
  }

  this.addorreplace(this.totalamount,{'billid':pbillid, totalamountpre:this.totalamountpre})

  return [ratetotal,amounttotal];
  
  });

  
 

})
})




    

    this.afs.collection('Bills').doc(pdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(pbillid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
          this.pcollection =   this.afs.collection('Bills').doc(pdate).collection(String(docy.data().routinebillid)).snapshotChanges()      
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );
   
    this.pcollection.subscribe((posts) => {





    this.afs.collection('Bills').doc(pdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(pbillid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
      this.afs.collection('Bills').doc(pdate).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {

           this.addorreplace2(this.arybilldetails,{'billid':pbillid,
            'code':doc.data().code,
            'productname': doc.data().productname,
            'hsncode' : doc.data().hsncode,
            'discount' : doc.data().discount,
            'qnty' : doc.data().qnty,
            'unit2' : doc.data().unit2,
            'rate': doc.data().rate,
            'cgst': doc.data().cgst,
            'sgst': doc.data().sgst,
            'igst': doc.data().igst,
          });


        }) ;
        return false;         
      }
    
    });
  });


    });
  });
});









  });

   return this.myary;
            }
           	
/*name of the excel-file which will be downloaded. */ 
fileName= 'Report'+moment().toDate()+'.xlsx';  

exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
	 
}

}